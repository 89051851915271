import React, { useState, useContext, useEffect } from 'react'
import { H1, BodyWrapper, P } from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import 'firebase/firestore'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

const MyCustomers = () => {
  const [loadingtable, setLoadingTable] = useState(true)
  const [customerList, setCustomerList] = useState([])
  const [refresh, setRefresh] = useState(0)

  const [searched, setSearched] = useState('')

  const { userState } = useContext(UserContext)

  const db = firebase.firestore()

  //handle paginaion events
  const [page, setPage] = useState(1)

  const PER_PAGE = 300

  const count = Math.ceil(customerList.length / PER_PAGE)
  const _DATA = usePagination(customerList, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }
  useEffect(() => {
    const fetchCustomerData = async () => {
      setLoadingTable(true)
      const data = await db
        .collection('Customers')
        .where('LeaderID', '==', userState.userId)

      const list = []

      data.get().then(doc => {
        console.log('DATA')

        let id = ''

        doc.forEach(docc => {
          // console.log(docc)
          const {
            Address,
            City,
            Email,
            Name,
            Neighborhood,
            PhoneNumber,
          } = docc.data()

          list.push({
            UID: docc.id,
            Name: Name,
            Address: Address,
            Neighborhood: Neighborhood,
            City: City,
            PhoneNumber: PhoneNumber,
            Email: Email,
          })

          id = docc.id
        })

        setCustomerList(list)
        setLoadingTable(false)
      })
    }

    fetchCustomerData()
  }, [refresh])

  //filter search request
  const requestSearch = searchedVal => {
    if (searchedVal !== '') {
      const filteredRows = customerList.filter(row => {
        let uidSearch = row.UID
        let nameSearch = row.Name
        let phoneNumberSearch = row.PhoneNumber
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(uidSearch) ||
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(null)
        )
      })
      setCustomerList(filteredRows)
    } else {
      setRefresh(refresh + 1)
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setRefresh(refresh + 1)
    }
  }

  const myCustomers = () => {
    return (
      <BodyWrapper>
        <H1>
          <b>My Customers:</b> {customerList.length}
        </H1>

        <SearchBar
          value={searched}
          onChange={searchVal => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{ marginBottom: '2em' }}
        />
        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>UID</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Name</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Address</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Neighborhood</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>City</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Phone Number</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Email</P>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingtable === false ? (
                _DATA.currentData().map(data => {
                  return (
                    <TableRow key={data.UID}>
                      <TableCell>{data.UID}</TableCell>
                      <TableCell>{data.Name}</TableCell>
                      <TableCell>{data.Address}</TableCell>
                      <TableCell>{data.Neighborhood}</TableCell>
                      <TableCell>{data.City}</TableCell>
                      <TableCell>{data.PhoneNumber}</TableCell>
                      <TableCell>{data.Email}</TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                  >
                    <CircularProgress color='#fe8f19' />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            width: '100%',
            marginTop: '2em',
            justifyContent: 'center',
          }}
          count={count}
          size='large'
          page={page}
          variant='outlined'
          shape='rounded'
          onChange={handlePageClickPagination}
        />
      </BodyWrapper>
    )
  }
  return myCustomers()
}

export default MyCustomers
