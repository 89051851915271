import React, { useState, useContext, useEffect } from "react";
import { Card, Divider, CardContent, MenuItem, Dialog, DialogContent, DialogActions, Table, TableHead, TableCell, TableBody, TableRow } from "@material-ui/core";
import { P, H1, H2, Button, BodyWrapper } from "../components";
import { UserContext } from "../contexts/userContext";
import firebase from "../firebase.js";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { DialogTitle } from "@mui/material";

const Commissions = () => {
  const [referralCode, setReferralCode] = useState("RF"+Math.floor(100000000 + Math.random() * 900000000))
  const [openReferral, setOpenReferral] = useState(false)
  const [noReferrals, setNoReferrals] = useState(false)
  const [Referrals, setReferrals] = useState([])
  const [LWactive, setLWActive] = useState(0)
  const [orders, setOrders] = useState(0)
  const [total, setTotal] = useState(0)
  const [refresh, setRefresh] = useState(0)
  const [date1, setDate1] = useState("")
  const [date2, setDate2] = useState("")
  const { userState } = useContext(UserContext);
  const db = firebase.firestore();
  const whatsappLink = "https://api.whatsapp.com/send?phone=&text="

  useEffect(() => {

    const fetchData = async () => {
     
      const list = []

      const data = await db.collection("CLReferrals").where("LeaderID", "==", userState.userId).orderBy("Date", "desc").get()
      
      if(data.empty){
        setNoReferrals(true)
        console.log("NO REFERRALS")
      }else{

        console.log("IMEPATIKANA")

        data.forEach((doc) => {
          const {Processed, Date} = doc.data() 

          list.push({
            key: doc.id,
            date: Date,
            processed: Processed,
            URL: "https://cl.tushop.io/referral/"+doc.id
          })

        })

        setReferrals(list)

      }

    }

    fetchData()

  }, [refresh]);

  const referralCodePopUp = () => {

    const handleClose = () => {

      setOpenReferral(false)
      setReferralCode("RF"+Math.floor(100000000 + Math.random() * 900000000))
      setRefresh(refresh+1)

    }

    const CLlink = "https://cl.tushop.io/referral/"+referralCode
    const message = "Hey! Use my referral code to sign up as a *Tushop* Community leader and *earn commission* working part time -> "+ CLlink

    return(
      <Dialog open={openReferral} onClose={handleClose}>

        <DialogTitle><H2>Referral Code: {referralCode} Created!</H2></DialogTitle>

        <DialogContent>
          <H2>Share the above referral code to friends and family to help grow the Tushop community!</H2>
          <P><i> (NB: Each referral code is only redeemable by one person only) </i></P>
          <br/>
          <Button onClick={() => {setRefresh(refresh+1)}}><WhatsAppIcon/> <a href={whatsappLink + message} target="_blank">Share via WhatsApp</a></Button>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>


      </Dialog>

    )
  }

  const commissions = () => {

    const handleCreateReferralCode = () => {

      const date = new Date();

      const today = date.getFullYear()+
        '-'+
        ((date.getMonth()+1)>9?(date.getMonth()+1):'0'+(date.getMonth()+1))+
        '-'+
        (date.getDate()>9?(date.getDate()):'0'+(date.getDate()))+
        'T'+
        (date.getHours()>9?(date.getHours()):'0'+(date.getHours()))+ 
        ":"+ 
        (date.getMinutes()>9?(date.getMinutes()):'0'+(date.getMinutes()));

      db.collection("CLReferrals").doc(referralCode).set({
        LeaderID: userState.userId,
        Processed: false,
        Date: today
      }).then(() => {
        setOpenReferral(true)
      })
    }

    const CLlink = "https://cl.tushop.io/referral/"
    const message = "Hey! Use my referral code to sign up as a *Tushop Community leader* and *earn commission* working part time -> "+ CLlink

    return (
      <BodyWrapper>
        {referralCodePopUp()}
        <H1>My Referrals</H1> 
        <Button onClick={handleCreateReferralCode}>Create Referral Code</Button>    
        <br/>   
        <TableContainer component={Paper} style={{maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell><P><b>Referral ID</b></P></TableCell>
                <TableCell><P><b>Date</b></P></TableCell>
                <TableCell><P><b>Share</b></P></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Referrals.map((data) => 
                <TableRow style={{backgroundColor: data.processed? "green" : "red", color: "white"}}>
                  <TableCell><p style={{color: "white"}}>{data.key}</p></TableCell>
                  <TableCell><p style={{color: "white"}}>{data.date}</p></TableCell>
                  <TableCell>
                    {data.processed?
                      <></>
                      :
                      <Button><a href={whatsappLink + message + data.key} target="_blank"><WhatsAppIcon/></a></Button>
                    }
                  </TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    );
  };
  return commissions()

};

export default Commissions;
