import React, { useState } from "react"
import { BodyWrapper } from "../components"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import CookiePolicyDoc from "../resources/docs/Cookie_Policy.pdf"

const CookiePolicy = () => {
  const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <BodyWrapper>
      <Document
        loading="please wait"
        file={CookiePolicyDoc}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={0.7} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </BodyWrapper>
  )
}

export default CookiePolicy
