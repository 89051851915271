import React, { useState, useContext, useEffect } from 'react'
import { P, H1, BodyWrapper, Button } from '../components'
import TextField from '@material-ui/core/TextField'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom'

const VerifyOTP = props => {
  const [OTPCode, setOTPCode] = useState('')
  const [btnLoading, setBtnLoading] = useState(false)
  const [redirectToKYC, setRedirectToKYC] = useState(false)
  const [pNo, setPNo] = useState('')
  const [inputCode, setinputCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [returned, setReturned] = useState(false)
  // eslint-disable-next-line
  const { userState, userDispatch } = useContext(UserContext)
  const db = firebase.firestore()
  console.log('yolllo', props.location.state.isVerified)
  useEffect(() => {
    const checkPhoneNumber = pNo => {
      if (pNo.length === 10) {
        const fullNo = '+254' + pNo.substring(1)

        setPNo(fullNo)

        fetchLeaderData(fullNo)
      } else if (pNo.length === 13) {
        setPNo(pNo)
        fetchLeaderData(pNo)
      }
    }

    const fetchLeaderData = async pNO => {
      const data = await db
        .collection('CommunityLeaders')
        .doc(props.location.state.leaderId)

      data.get().then(doc => {
        console.log('DOC!')

        if (doc.exists) {
          const { OTP } = doc.data()

          if (OTP === '0') {
            SendOTP(pNO)
            setReturned(false)
          } else {
            const savedInpputCode = window.localStorage.getItem('OTP')

            setinputCode(savedInpputCode)
            setOTPCode(OTP)
            setReturned(true)
          }
        } else {
          console.log('Mismatch')
          console.log(props.location.state.leaderId)
        }
      })
    }

    checkPhoneNumber(props.location.state.phoneNumber)
  }, [])

  const SendOTP = to => {
    setBtnLoading(true)

    const otpCode = Math.floor(1000 + Math.random() * 9000).toString()

    const url = 'https://api.tushop.io/api/v1/sms/OTP'

    fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        OTP: otpCode,
        // to: props.location.state.phoneNumber,
        to: to,
        CLID: null,
      }),
    })
      .then(response => {
        if (response.status === 201) {
          return response.json()
        } else {
          throw new Error('Something went wrong on api server!')
        }
      })
      .then(response => {
        console.debug(response)

        setOTPCode(otpCode)
      })
      .then(() => {
        updateLeaderOTP(otpCode)
      })
      .catch(error => {
        console.error(error)
      })
  }

  const updateLeaderOTP = async otpCode => {
    const data = await db
      .collection('CommunityLeaders')
      .doc(props.location.state.leaderId)

    data
      .update({
        OTP: otpCode,
      })
      .then(() => {
        alert('OTP code sent!')
        setBtnLoading(false)
      })
  }

  const handleChange = e => {
    setinputCode(e.target.value)
    setCodeError(false)
  }

  const handleSubmit = e => {
    if (inputCode.length === 4 && inputCode !== OTPCode.toString()) {
      setBtnLoading(true)
      setCodeError(true)
      setBtnLoading(false)
    } else if (
      inputCode === OTPCode.toString() &&
      props.location.state.isVerified
    ) {
      setBtnLoading(true)
      console.log('Match! Redirect to Dashboard')
      userDispatch({ type: 'name', payload: props.location.state.leaderName })
      userDispatch({ type: 'pNo', payload: props.location.state.leaderPNO })
      userDispatch({ type: 'userId', payload: props.location.state.leaderId })
      userDispatch({ type: 'BA', payload: props.location.state.BA })

      window.localStorage.setItem('OTP', OTPCode)
      setBtnLoading(false)
    } else if (
      inputCode === OTPCode.toString() &&
      !props.location.state.isVerified
    ) {
      setRedirectToKYC(true)
    } else {
      setBtnLoading(true)
      setCodeError(false)
      setBtnLoading(false)
    }
  }

  const verifyOTP = () => {
    return (
      <BodyWrapper>
        <H1>Verify OTP</H1>

        {!returned ? (
          <div>
            <P>A four digit passcode has been sent to {pNo}</P>
            {/* eslint-disable-next-line */}
            <P>
              <a onClick={e => SendOTP(pNo)}>CLICK HERE</a> if you did not
              recieve an SMS
            </P>

            <P>Input the code below to proceed.</P>

            <P>
              <i>
                <b>NOTE:</b> Save the passcode for future logins
              </i>
            </P>
          </div>
        ) : (
          <div>
            <P>Input the 4 digit passcode</P>
            <P></P>
            {/* eslint-disable-next-line */}
            <P>
              <a onClick={e => SendOTP(pNo)}>CLICK HERE</a> if you lost your
              code
            </P>
          </div>
        )}

        <TextField
          id="standard-basic"
          style={{ width: '100%', marginTop: '20px', alignItems: 'start' }}
          value={inputCode}
          error={codeError}
          inputProps={{ maxLength: 4 }}
          helperText={codeError ? 'Invalid Code!' : ' '}
          onChange={e => handleChange(e)}
          name="OTP"
          placeholder="OTP"
        />

        <Button onClick={handleSubmit}>
          {btnLoading === true ? (
            <CircularProgress color="#fff" size={22} />
          ) : null}
          <span style={{ marginLeft: '1em' }}>SUBMIT</span>
        </Button>
      </BodyWrapper>
    )
  }

  if (redirectToKYC) {
    console.log('LEADERID: ', props.location.state.leaderId)

    return (
      <Redirect
        to={{
          pathname: '/kyc',
          state: {
            phoneNumber: props.location.state.phoneNumber,
            leaderId: props.location.state.leaderId,
            leaderName: props.location.state.leaderName,
            leaderPNO: props.location.state.leaderPNO,
            BA: props.location.state.BA,
          },
        }}
      />
    )
  } else {
    console.log('YOW')
    return (
      <BodyWrapper>
        <H1>Verify OTP</H1>

        {!returned ? (
          <div>
            <P>A four digit passcode has been sent to {pNo}</P>
            {/* eslint-disable-next-line */}
            <P>
              <a onClick={e => SendOTP(pNo)}>CLICK HERE</a> if you did not
              recieve an SMS
            </P>

            <P>Input the code below to proceed.</P>

            <P>
              <i>
                <b>NOTE:</b> Save the passcode for future logins
              </i>
            </P>
          </div>
        ) : (
          <div>
            <P>Input the 4 digit passcode</P>
            <P></P>
            {/* eslint-disable-next-line */}
            <P>
              <a onClick={e => SendOTP(pNo)}>CLICK HERE</a> if you lost your
              code
            </P>
          </div>
        )}

        <TextField
          id="standard-basic"
          style={{ width: '100%', marginTop: '20px', alignItems: 'start' }}
          value={inputCode}
          error={codeError}
          inputProps={{ maxLength: 4 }}
          helperText={codeError ? 'Invalid Code!' : ' '}
          onChange={e => handleChange(e)}
          name="OTP"
          placeholder="OTP"
        />

        <Button onClick={handleSubmit}>
          {btnLoading === true ? (
            <CircularProgress color="#fff" size={22} />
          ) : null}
          <span style={{ marginLeft: '1em' }}>SUBMIT</span>
        </Button>
      </BodyWrapper>
    )
  }
}

export default VerifyOTP
