import React, { useState, useContext } from 'react'
import { P, H1, H2, Button, Input, Form, BodyWrapper } from '../components'
import firebase from '../firebase.js'
import { UserContext } from '../contexts/userContext'
import { createWorker } from 'tesseract.js'
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Link,
  Redirect,
} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const KYC = props => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [errMessage, setErrMessage] = useState(null)
  const [idFront, setIDFront] = useState(null)
  const [btnLoadingFront, setBtnLoadingFront] = useState(false)
  const [btnLoadingBack, setBtnLoadingBack] = useState(false)
  const [btnPassFront, setBtnPassFront] = useState(false)
  const [btnPassBack, setBtnPassBack] = useState(false)
  const [idFrontUrl, setIDFrontUrl] = useState('')
  const [idBack, setIDBack] = useState(null)
  const [idBackUrl, setIDBackUrl] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDOB] = useState('')
  const [idNo, setIDNo] = useState('')
  const [kraPin, setKraPin] = useState('')
  const [agreeToTerms, setAgreeToTerms] = useState(false)
  const [agreeToCookie, setAgreeToCookie] = useState(false)

  const db = firebase.firestore()
  const storage = firebase.storage()

  const { userState, userDispatch } = useContext(UserContext)

  const recognizeFrontID = async () => {
    try {
      setBtnLoadingFront(true)
      const Reference = storage.ref(
        `CLKYC/ID/${props.location.state.leaderId}/front/${idFront.name}`
      )

      Reference.put(idFront).then(snapshot => {
        Reference.getDownloadURL()
          .then(url => {
            const urlString = url.toString()
            setIDFrontUrl(urlString)
            setBtnLoadingFront(false)
            setBtnPassFront(true)
          })
          .catch(error => {
            setBtnLoadingFront(false)
            setBtnPassFront(false)
            alert('Upload failed')
          })
      })
    } catch (error) {
      alert('Upload failed')
    }
  }

  const recognizeBackID = () => {
    try {
      setBtnLoadingBack(true)
      const Reference = storage.ref(
        `CLKYC/ID/${props.location.state.leaderId}/back/${idBack.name}`
      )
      Reference.put(idBack).then(snapshot => {
        Reference.getDownloadURL()
          .then(url => {
            const urlString = url.toString()
            setIDBackUrl(urlString)
            setBtnLoadingBack(false)
            setBtnPassBack(true)
          })
          .catch(error => {
            setBtnLoadingBack(false)
            setBtnPassBack(false)
            setErrMessage('Upload failed')
            alert('Upload failed')
          })
      })
    } catch (error) {
      alert('Upload failed')
    }
  }

  const isValidDate = date => {
    if (date.charAt(2) == '/' && date.charAt(5) == '/') {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = () => {
    const valueDatePass = isValidDate(dob)

    if (valueDatePass === false) {
      alert('Please enter valid date of birth in format DD/MM/YYYY')
    }
    if (idFrontUrl === '') {
      alert('Please upload a valid front ID')
    } else if (idBackUrl === '') {
      alert('Please upload a valid back ID')
    } else if (dob === '') {
      alert('Please enter valid date of birth in format DD/MM/YYYY')
    } else if (idNo === '') {
      alert('Please enter valid ID number')
    } else if (firstName === '') {
      alert('Please enter valid legal first name')
    } else if (lastName === '') {
      alert('Please enter valid legal first name')
    } else if (agreeToTerms === false) {
      alert('Please accept our terms and conditions to proceed')
    } else if (agreeToCookie === false) {
      alert('Please accept our cookie agreement to proceed')
    } else {
      if (btnPassBack === true && btnPassFront) {
        setBtnLoading(true)
        const kyc = {
          FirstName: firstName,
          LastName: lastName,
          IDNo: idNo,
          FrontIDUrl: idFrontUrl,
          BackIDUrl: idBackUrl,
          kraPin,
          DOB: dob,
          agreeToCookie,
          agreeToTerms,
        }
        db.collection('CommunityLeaders')
          .doc(props.location.state.leaderId)
          .update({
            Verified: true,
            KYC: kyc,
            TNCAgree: agreeToTerms,
            CookieAgreed: agreeToCookie,
            KYCUpdated: true,
          })
          .then(() => {
            userDispatch({
              type: 'name',
              payload: props.location.state.leaderName,
            })
            userDispatch({
              type: 'pNo',
              payload: props.location.state.leaderPNO,
            })
            userDispatch({
              type: 'userId',
              payload: props.location.state.leaderId,
            })
            userDispatch({ type: 'BA', payload: props.location.state.BA })
          })
        setBtnLoading(false)
      } else {
        alert('make sure files are uploaded')
      }
    }
  }

  return (
    <>
      <BodyWrapper>
        <H1>Hey there!</H1>
        <H2>
          Please allow us to capture a few details for completion of
          registration.
        </H2>
        <br />
        <P>
          <b>ID: Front: </b>
          <br />
          -Please ensure that the image is upright and not tilted <br />
          -Upload type should be of image type and not PDF type
        </P>
        <input
          type='file'
          name='file'
          onChange={e => setIDFront(e.target.files[0])}
        />
        <Button onClick={recognizeFrontID}>
          {btnLoadingFront === true ? (
            <CircularProgress
              color='white'
              size={22}
              style={{ paddingRight: '1em' }}
            />
          ) : null}
          {btnPassFront == false
            ? ' Upload ID front file'
            : 'Successful upload'}
        </Button>
        <br />
        {btnPassFront ? (
          <img src={idFrontUrl} style={{ width: '100%' }} />
        ) : null}

        <br />
        <P>
          <b>ID: Back</b>
        </P>
        <input
          type='file'
          name='file'
          onChange={e => setIDBack(e.target.files[0])}
        />
        <Button onClick={recognizeBackID}>
          {btnLoadingBack === true ? (
            <CircularProgress
              color='#fff'
              size={22}
              style={{ paddingRight: '1em' }}
            />
          ) : null}
          {btnPassBack == false
            ? errMessage == null
              ? ' Upload ID back file'
              : errMessage
            : 'Successful upload'}
        </Button>
        <br />
        {btnPassBack ? <img src={idBackUrl} style={{ width: '100%' }} /> : null}
        <br />
        <Input
          onChange={e => setIDNo(e.target.value)}
          name='phoneNumber'
          placeholder='ID number (As per ID)'
        />
        <Input
          onChange={e => setFirstName(e.target.value)}
          name='FirstName'
          placeholder='Legal First Name (As Per ID)'
        />
        <br />
        <Input
          onChange={e => setLastName(e.target.value)}
          name='LastNamer'
          placeholder='Legal Last Name (As Per ID)'
        />
        <br />
        <Input
          onChange={e => setDOB(e.target.value)}
          name='dob'
          placeholder='Legal Date Of Birth DD/MM/YYYY (As Per ID)'
        />
        <br />
        <Input
          onChange={e => setKraPin(e.target.value)}
          name='kraPIN'
          placeholder='KRA PIN (Optional)'
        />
        <br />
        <P>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setAgreeToTerms(!agreeToTerms)} />
            }
          />
          I acknowledge that I have read and understood the{' '}
          <Link target='_blank' to='/terms-conditions'>
            terms & conditions
          </Link>{' '}
          and the{' '}
          <Link target='_blank' to='/privacy-policy'>
            privacy policy
          </Link>{' '}
          of this agreement
        </P>
        <P>
          <FormControlLabel
            control={
              <Checkbox onChange={e => setAgreeToCookie(!agreeToCookie)} />
            }
          />
          Accept all cookies. By accepting, you agree Tushop Pamoja Limited can
          store cookies on your device as per the{' '}
          <Link target='_blank' to='/cookie-policy'>
            cookie policy
          </Link>
        </P>
        <Button marginBottom onClick={handleSubmit}>
          {btnLoading === true ? (
            <CircularProgress color='#fff' size={22} />
          ) : null}
          <span style={{ marginLeft: '1em' }}>Sign in</span>
        </Button>
      </BodyWrapper>
    </>
  )
}

export default KYC
