import React from "react";
import { H1, H2, P, Button, BodyWrapper } from "../components";
import { withRouter } from "react-router-dom";

const Lander = props => {
  const signIn = () => {
    props.history.push("/signin");
  };

  return (
    <BodyWrapper>
      <H1>Community Leader Admin</H1>
      <H2>
        A fully-featured single-page app for our Community Leaders
        To assist them in carrying out Tushop's day to day activities
      </H2>
      <P>
        <li>
          Get to see your pending orders for your customers in the pending 
          orders page.
        </li>
        <br/>
        <li>
          Get to see all your completed orders for your customers in the 
          completed orders page.
        </li>
        <br/>
        <li>
          Get a total list of all customers registered under your link
        </li>
        <br/>
        <li>
          Basic security rules and authentication for login 
          to secure your data.
        </li>
        <br/>
        <li>
          Dark Mode! You gotta have dark mode!
        </li>
      </P>
      <Button onClick={signIn}>Get Started</Button>
    </BodyWrapper>
  );
};

export default withRouter(Lander);
