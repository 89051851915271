import React, { useState, useContext, useEffect } from 'react'
import { H1, BodyWrapper, CenteredDiv, Spinner, Button, P } from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import RefreshIcon from '@material-ui/icons/Refresh'
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Switch from '@material-ui/core/Switch'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { firestore } from 'firebase'
import SearchBar from 'material-ui-search-bar'

const PendingOrders = () => {
  const { userState } = useContext(UserContext)
  const [searched, setSearched] = useState('')
  const [pendingOrderList, setPendingOrderList] = useState([])
  const [displayCart, setDisplayCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [cartID, setCartID] = useState('')
  const [yesterday, setYesterday] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [grandTotal, setGrandTotal] = useState('')
  const [cartItems, setCartItems] = useState([])
  const [aggregate, setAggregate] = useState([])
  const db = firebase.firestore()
  const realtime = firebase.database()

  useEffect(() => {
    setLoading(true)
    const fetchOrderData = async () => {
      const cartList = []

      const aggregateList = []

      const date = new Date()

      date.setDate(date.getDate() - 1)

      const today =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : '0' + (date.getMonth() + 1)) +
        '-' +
        (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
        ' T 22:00'

      const date2 = new Date()

      date2.setDate(date2.getDate() - 3)

      const twoDaysAgo =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 > 9
          ? date2.getMonth() + 1
          : '0' + (date2.getMonth() + 1)) +
        '-' +
        (date2.getDate() > 9 ? date2.getDate() : '0' + date2.getDate()) +
        ' T 22:00'

      const data = await db
        .collection('Orders')
        .where('DateOfOrder', '>=', twoDaysAgo)
        .where('LeaderID', '==', userState.userId)
        .where('DateOfOrder', '<=', today)
        .orderBy('DateOfOrder', 'asc')
        .get()

      setYesterday(today)

      data.forEach(doc => {
        const {
          CustomerID,
          CustomerName,
          CartID,
          CustomerPNo,
          DeliveryAddress,
          LeaderID,
          LeaderName,
          TotalPrice,
          DateOfOrder,
          CDelivery,
          POD,
          Return,
          CLConfirm,
        } = doc.data()

        if (CartID.charAt(0) === 'C') {
          const shoppingCartRef = firebase
            .database()
            .ref('shoppingCarts/' + CartID)
          shoppingCartRef.on('value', snapshot => {
            if (snapshot.exists()) {
              snapshot.forEach(function(childSnapshot) {
                if (childSnapshot.key !== 'grandTotal') {
                  const data = childSnapshot.val()

                  cartList.push({
                    OrderID: doc.id,
                    customerID: CustomerID,
                    customerName: CustomerName,
                    cartID: CartID,
                    pNo: CustomerPNo,
                    address: DeliveryAddress,
                    leaderID: LeaderID,
                    leaderName: LeaderName,
                    productDesc: data.productDesc,
                    productPrice: data.productPrice,
                    quantity: data.quantity,
                    total: data.total,
                    return: Return ? 'True' : 'False',
                    status: POD ? 'POD' : 'Paid',
                    CLConfirm: CLConfirm,
                    DateOfOrder: DateOfOrder,
                  })

                  if (aggregateList[childSnapshot.key] === undefined) {
                    console.log('Ohaa', data.quantity)
                    aggregateList[childSnapshot.key] = parseInt(data.quantity)
                  } else {
                    console.log('Ohaa2', aggregateList[childSnapshot.key])
                    aggregateList[childSnapshot.key] =
                      aggregateList[childSnapshot.key] + parseInt(data.quantity)
                  }
                }
              })

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        } else if (CartID.charAt(0) === 'G') {
          db.collection('GBQueue')
            .doc(CartID)
            .get()
            .then(doc => {
              const { GBID, Quantity } = doc.data()

              db.collection('GroupBuy')
                .doc(GBID)
                .get()
                .then(doc1 => {
                  const { ProductID } = doc1.data()

                  if (aggregateList[ProductID] === undefined) {
                    console.log('Ohaa', data.quantity)
                    aggregateList[ProductID] = parseInt(Quantity)
                  } else {
                    console.log('Ohaa2', aggregateList[ProductID])
                    aggregateList[ProductID] =
                      aggregateList[ProductID] + parseInt(Quantity)
                  }
                })
            })
        }
      })

      setTimeout(() => {
        console.log('MHMMM')
        setLoading(false)
        setAggregate(aggregateList)
        setPendingOrderList(cartList)
      }, 3000)

      console.log('PIMBI', aggregate)
    }

    fetchOrderData()
  }, [refresh])

  //filter search request
  const requestSearch = searchedVal => {
    if (searchedVal !== '') {
      const filteredRows = pendingOrderList.filter(row => {
        let orderSearch = row.OrderID
        let nameSearch = row.customerName
        let phoneNumberSearch = row.pNo
        let dateSearch = row.DateOfOrder

        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(orderSearch) ||
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(dateSearch) ||
          expression.test(null)
        )
      })
      setPendingOrderList(filteredRows)
    } else {
      setRefresh(refresh + 1)
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setRefresh(refresh + 1)
    }
  }

  const handleChangeActivity = e => {
    e.preventDefault()

    setLoading(true)

    const OrderRef = db.collection('Orders').doc(e.target.name)

    OrderRef.update({
      CLConfirm: e.target.checked,
      CDelivery: false,
    })
      .then(() => {
        console.log('Document successfully updated!')
        setRefresh(refresh + 1)
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleDisplayCart = () => {
    console.log('AGGREGATE!', aggregate)

    const listCart = []

    setLoading(true)

    const fetchData = async () => {
      for (var key in aggregate) {
        const quantity = aggregate[key]

        console.log(key, 'OHAA')

        await db
          .collection('Products')
          .doc(key)
          .get()
          .then(doc => {
            if (doc.exists) {
              const { Description } = doc.data()

              listCart.push({
                Description: Description,
                Qty: quantity,
              })

              console.log('PUSHED!')
            }
          })
          .then(() => {
            console.log('1', listCart)
            console.log('SET!')
          })
      }

      setCartItems(listCart)
      setDisplayCart(true)
      setLoading(false)
      console.log('LASTLY!')
    }

    fetchData()
  }

  const handleCloseCart = () => {
    setDisplayCart(false)
    setCartID('')
    setCartItems([])
    setGrandTotal('')
  }

  const DialogCart = () => {
    return (
      <Dialog
        open={displayCart}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Aggregate</DialogTitle>

        <DialogContent>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map(data => (
                <TableRow key={data.Description}>
                  <TableCell>{data.Description}</TableCell>
                  <TableCell>{data.Qty}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart}>Cancel</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const pendingOrders = () => {
    const handleRefresh = () => {
      setRefresh(refresh + 1)
    }

    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: '50%',
      left: 'auto',
      position: 'fixed',
      zIndex: 1,
    }

    return (
      <BodyWrapper>
        {DialogCart()}
        {loading ? (
          <CenteredDiv vertical>
            <Spinner large secondary style={loader} />
            <P style={loader}>Please wait...</P>
          </CenteredDiv>
        ) : (
          <></>
        )}
        <H1>
          <b>Incoming Deliveries:</b> {pendingOrderList.length}
        </H1>
        <Button
          onClick={handleDisplayCart}
          style={{ display: 'inline-flex', width: '15%', marginRight: '2em' }}
        >
          <FormatListNumberedRtlIcon />
        </Button>
        <Button
          onClick={handleRefresh}
          style={{ display: 'inline-flex', width: '15%' }}
        >
          <RefreshIcon />
        </Button>
        <hr />
        <br />
        <SearchBar
          value={searched}
          onChange={searchVal => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{ marginBottom: '2em' }}
        />

        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Order ID</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Name</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Phone</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Product</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Qty</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Price</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Total</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Delivery Address</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Status</b>
                  </P>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <P>
                    <b>Date</b>
                  </P>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pendingOrderList.map(data => (
                <TableRow key={data.OrderID + data.productDesc}>
                  <TableCell>{data.OrderID}</TableCell>
                  <TableCell>{data.customerName}</TableCell>
                  <TableCell>{data.pNo}</TableCell>
                  <TableCell>{data.productDesc}</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>{data.productPrice}</TableCell>
                  <TableCell>{data.total}</TableCell>
                  <TableCell>{data.address}</TableCell>
                  <TableCell> {data.status ? 'POD' : 'Paid'}</TableCell>
                  <TableCell>{data.DateOfOrder}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    )
  }
  return pendingOrders()
}

export default PendingOrders
