import React, { useState } from "react"
import styled from "styled-components"
import { metrics } from "../themes"
import { H1, H2, P, BodyWrapper } from "../components"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import PrivacyPolicyDoc from "../resources/docs/Privacy_Policy.pdf"

const OL = styled.ol`
  font-size: ${metrics.smallText}px;
  line-height: 2;
  margin-left: ${metrics.baseUnit * 3}px;
  padding-left: 0;
  margin-bottom: ${metrics.baseUnit * 3}px;
`

const UL = styled.ul`
  font-size: ${metrics.smallText}px;
  line-height: 2;
  margin-left: ${metrics.baseUnit * 3}px;
  padding-left: 0;
  margin-bottom: ${metrics.baseUnit * 3}px;
`

const PrivacyPolicy = () => {
  const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <BodyWrapper>
      <Document
        loading="please wait"
        file={PrivacyPolicyDoc}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={0.7} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </BodyWrapper>
  )
}

export default PrivacyPolicy
