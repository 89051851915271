import React, { useReducer, createContext } from "react"
const styleMode = window.localStorage.getItem("styleMode")

const initialState = {
  userId: null,
  name: null,
  pNo: null,
  BA: false,
  KYCUpdated: false,
  styleMode: styleMode ? styleMode : "main",
}
export const UserContext = createContext(initialState)

const reducer = (state, action) => {
  switch (action.type) {
    case "userId":
      return { ...state, userId: action.payload }
    case "name":
      return { ...state, name: action.payload }
    case "pNo":
      return { ...state, pNo: action.payload }
    case "BA":
      return { ...state, BA: action.payload }
    case "styleMode":
      return { ...state, styleMode: action.payload }
    case "kyc":
      return { ...state, KYCUpdated: action.payload }
    case "signOut":
      return { ...initialState }
    default:
      return state
  }
}

export const UserProvider = props => {
  const [userState, userDispatch] = useReducer(reducer, initialState)
  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  )
}
