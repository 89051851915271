import React, { useState } from 'react'
import { P, H1, Button, Input, Form, BodyWrapper } from '../components'
import firebase from '../firebase.js'
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Redirect,
} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'

const SignIn = () => {
  const [btnLoading, setBtnLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [kyc, setKYC] = useState(false)
  const [isVerified, setisVerified] = useState(false)
  const [leaderId, setLeaderId] = useState('')
  const [leaderName, setLeaderName] = useState('')
  const [leaderPNO, setLeaderPNo] = useState('')
  const [proceedToOTP, setProceedToOTP] = useState(false)
  const [BA, setBA] = useState(false)
  const db = firebase.firestore()

  const onClickSubmit = async () => {
    console.log('FULL NOOOOO!', phoneNumber)
    setBtnLoading(true)
    const data = await db
      .collection('CommunityLeaders')
      .where('PhoneNumber', '==', phoneNumber)

    data.get().then(doc => {
      console.log('DOC!')

      if (!doc.empty) {
        doc.forEach(record => {
          const { Name, PhoneNumber, BA, KYCUpdated, Verified } = record.data()

          console.log('EXISTS ', record.id)

          console.log('Match')

          setLeaderId(record.id)

          setLeaderName(Name)

          setLeaderPNo(PhoneNumber)

          setBA(BA)

          setKYC(KYCUpdated)
          setisVerified(Verified)

          setProceedToOTP(true)
          setBtnLoading(false)
        })
      } else {
        console.log('Mismatch')
        console.log(phoneNumber)
        setBtnLoading(false)
      }
    })
  }

  const signin = () => {
    return (
      <>
        <BodyWrapper>
          <H1>Sign In</H1>
          <P>
            Sign in using your registered community leader phone number to
            proceed
          </P>
          <Form>
            <div>
              <Input
                onChange={e => setPhoneNumber(e.target.value)}
                name="phoneNumber"
                placeholder="Community Leader Phone Number"
              />
            </div>
          </Form>
          <div>
            <Button marginBottom onClick={onClickSubmit}>
              {btnLoading === true ? (
                <CircularProgress color="#fff" size={22} />
              ) : null}
              <span style={{ marginLeft: '1em' }}>Sign in</span>
            </Button>
          </div>
        </BodyWrapper>
      </>
    )
  }

  // if(proceedToOTP){

  //   return <Redirect
  //                       push
  //                       to={{
  //                           pathname: "/dashboard",
  //                       }}
  //                   />
  // }

  if (proceedToOTP) {
    return (
      <Redirect
        to={{
          pathname: '/verifyOTP',
          state: {
            phoneNumber: phoneNumber,
            leaderId: leaderId,
            leaderName: leaderName,
            leaderPNO: leaderPNO,
            BA: BA,
            kyc: kyc,
            isVerified: isVerified,
          },
        }}
      />
    )
  } else {
    return signin()
  }
}

export default SignIn
