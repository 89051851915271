import React, { useState, useContext, useEffect } from 'react'
import {
  H1,
  P,
  H2,
  Button,
  BodyWrapper,
  Spinner,
  CenteredDiv,
} from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import 'firebase/firestore'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

const Commissions = () => {
  const [qty, setQty] = useState(0)
  const [qtyPOD, setQtyPOD] = useState(0)
  const [qtyPrePay, setQtyPrePay] = useState(0)
  const [TotalAmount, setTotalAmount] = useState(0)
  const [totalcommission, setTotalCommission] = useState(0)
  const [totalbonus, setTotalBonus] = useState(0)
  const [totalbonusPOD, setTotalBonusPOD] = useState(0)
  const [ledgerBalance, setLedgerBalance] = useState(0)
  const [commissionArray, setCommissionArray] = useState([])
  const [searched, setSearched] = useState('')
  const [refresh, setRefresh] = useState(0)

  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(true)
  const [category, setCategory] = useState('1')
  const [date1, setDate1] = useState('')
  const [date2, setDate2] = useState('')

  const { userState } = useContext(UserContext)
  const db = firebase.firestore()

  const date = new Date()

  const yesterday2300 =
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 > 9
      ? date.getMonth() + 1
      : '0' + (date.getMonth() + 1)) +
    '-' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    'T23:00'

  //handle paginaion events
  const [page, setPage] = useState(1)

  const PER_PAGE = 300

  const count = Math.ceil(commissionArray.length / PER_PAGE)
  const _DATA = usePagination(commissionArray, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }
  useEffect(() => {
    const fetchLedgerData = () => {
      const yesterdate = new Date()

      let totalDefaulted = 0

      yesterdate.setDate(yesterdate.getDate() - 3)

      const threeDaysAgo =
        yesterdate.getFullYear() +
        '-' +
        (yesterdate.getMonth() + 1 > 9
          ? yesterdate.getMonth() + 1
          : '0' + (yesterdate.getMonth() + 1)) +
        '-' +
        (yesterdate.getDate() > 9
          ? yesterdate.getDate()
          : '0' + yesterdate.getDate()) +
        ' T ' +
        (yesterdate.getHours() > 9
          ? yesterdate.getHours()
          : '0' + yesterdate.getHours()) +
        ':' +
        (yesterdate.getMinutes() > 9
          ? yesterdate.getMinutes()
          : '0' + yesterdate.getMinutes())

      db.collection('Ledger')
        .where('LeaderID', '==', userState.userId)
        .where('Date', '<', threeDaysAgo)
        .where('Paid', '==', false)
        .where('Hold', '==', false)
        .get()
        .then(data => {
          if (data.empty) {
            console.log('EMPTY DEFAULTED LEDGERS!')
            setLedgerBalance(0)
          } else {
            data.forEach(doc => {
              const { Total } = doc.data()

              totalDefaulted = totalDefaulted + parseInt(Total)
            })
          }
        })
        .then(() => {
          setLedgerBalance(-totalDefaulted)
        })
    }

    const checkDayOfWeek = () => {
      const day = date.getDay()

      console.log(day)

      setDateLastWeek(day)
    }

    const setDateLastWeek = days => {
      let date1 = new Date(yesterday2300)

      let date2 = new Date(yesterday2300)

      date1.setDate(date1.getDate() - (days + 7))

      date2.setDate(date2.getDate() - (days))

      const date01 =
        date1.getFullYear() +
        '-' +
        (date1.getMonth() + 1 > 9
          ? date1.getMonth() + 1
          : '0' + (date1.getMonth() + 1)) +
        '-' +
        (date1.getDate() > 9 ? date1.getDate() : '0' + date1.getDate()) +
        ' T 23:00'

      const date02 =
        date2.getFullYear() +
        '-' +
        (date2.getMonth() + 1 > 9
          ? date2.getMonth() + 1
          : '0' + (date2.getMonth() + 1)) +
        '-' +
        (date2.getDate() > 9 ? date2.getDate() : '0' + date2.getDate()) +
        ' T 23:00'
      setDate1(date01)
      setDate2(date02)

      fetchLastWeekCommissionsData(date01, date02)
    }

    const fetchLastWeekCommissionsData = async (date01, date02) => {
      const data = await db
        .collection('PayOuts')
        .where('LeaderID', '==', userState.userId)
        .where('DateCreated', '>=', date01)
        .where('DateCreated', '<', date02)
        .where('Processed', '==', false)
        .get()

      let Qty = 0
      let QtyPOD = 0
      let QtyPrePay = 0
      let totalCommission = 0
      let totalBonus = 0
      let totalBonusPOD = 0
      let totalAmount = 0

      let Total = 0

      if (!data.empty) {
        data.forEach(doc => {
          const { Bonus, POD, TotalCommission, TotalAmount } = doc.data()

          if (Bonus) {
            if (POD) {
              totalBonusPOD = totalBonusPOD + TotalCommission
              QtyPOD = QtyPOD + 1
            } else {
              totalBonus = totalBonus + TotalCommission
              QtyPrePay = QtyPrePay + 1
            }
          } else {
            totalAmount = totalAmount + TotalAmount

            totalCommission = totalCommission + TotalCommission

            Qty = Qty + 1
          }

          Total = Total + TotalCommission
        })

        setQty(Qty)
        setTotalAmount(totalAmount)
        setTotalCommission(totalCommission)

        setQtyPOD(QtyPOD)
        setTotalBonusPOD(totalBonusPOD)

        setQtyPrePay(QtyPrePay)
        setTotalBonus(totalBonus)

        setTotal(Total)
        setLoading(false)
      } else {
        console.log('EMPTY RECORDS')
        setLoading(false)
      }
      // setLWActive(Active)
    }

    const getCommissions = async (e, CLID, open) => {
      setLoading(true)
      const CommissionList = []

      const data = await db
        .collection('PayOuts')
        .where('LeaderID', '==', userState.userId)
        .orderBy('DateCreated', 'desc')

      data
        .get()
        .then(data => {
          data.forEach(doc => {
            const {
              Bonus,
              TotalCommission,
              TotalAmount,
              OrderID,
              CartID,
              DateCreated,
              Processed,
            } = doc.data()

            CommissionList.push({
              key: doc.id,
              TotalCommission,
              TotalAmount,
              OrderID,
              CartID,
              DateCreated,
              Processed,
              Bonus,
            })
          })
        })
        .then(() => {
          setCommissionArray(CommissionList)
          setLoading(false)
        })
    }

    if (category === '1') {
      checkDayOfWeek()
      fetchLedgerData()
    } else if (category === '2') {
      getCommissions()
    }
  }, [category, refresh])

  //filter search request
  const requestSearch = searchedVal => {
    if (searchedVal !== '') {
      const filteredRows = commissionArray.filter(row => {
        let keySearch = row.key
        let orderIdSearch = row.OrderID
        let cartIdSearch = row.CartID
        let dateSearch = row.DateCreated
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(orderIdSearch) ||
          expression.test(cartIdSearch) ||
          expression.test(dateSearch) ||
          expression.test(null)
        )
      })
      setCommissionArray(filteredRows)
    } else {
      setRefresh(refresh + 1)
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setRefresh(refresh + 1)
    }
  }
  const load = () => {
    const loader = {
      margin: 0,
      top: 'auto',
      right: 'auto',
      bottom: '50%',
      left: 'auto',
      position: 'fixed',
      zIndex: 1,
    }

    if (loading) {
      return (
        <CenteredDiv vertical>
          <Spinner large secondary style={loader} />
        </CenteredDiv>
      )
    }
  }

  const commissions = () => {
    const handleSelectCategory = (e, newVal) => {
      setCategory(newVal)
    }

    return (
      <BodyWrapper>
        <H1>Commissions</H1>
        <Tabs
          onChange={(e, newVal) => {
            handleSelectCategory(e, newVal)
          }}
          value={category}
          variant='fullWidth'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          <Tab label={'SUMMARY'} value={'1'} />
          <Tab label={'RECORDS'} value={'2'} />
        </Tabs>
        {category === '1' ? (
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow key='0'>
                  <TableCell colSpan={3}>
                    <H2 style={{ textAlign: 'center' }}>
                      Commissions for orders between {date1} - {date2}
                    </H2>
                  </TableCell>
                </TableRow>
                <TableRow key='1'>
                  <TableCell colSpan={3}>
                    <H2 style={{ textAlign: 'center' }}>Total Orders: {qty}</H2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ background: '#fe7c19' }}>
                    <P>Type</P>
                  </TableCell>
                  <TableCell style={{ background: '#D3D3D3' }}>
                    <P>Total Amount (Ksh)</P>
                  </TableCell>
                  <TableCell>
                    <P>Commission (Ksh)</P>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key='2'>
                  <TableCell style={{ background: '#fe7c19' }}>
                    <P>All Orders</P>
                  </TableCell>
                  <TableCell style={{ background: '#D3D3D3' }}>
                    <P>{TotalAmount} </P>
                  </TableCell>
                  <TableCell>
                    <P>{totalcommission}</P>
                  </TableCell>
                </TableRow>
                <TableRow key='3'>
                  <TableCell colSpan={3}>
                    <H2 style={{ textAlign: 'center' }}>Bonuses</H2>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ background: '#fe7c19' }}>
                    <P>Type</P>
                  </TableCell>
                  <TableCell style={{ background: '#D3D3D3' }}>
                    <P>Total Orders</P>
                  </TableCell>
                  <TableCell>
                    <P>Bonus (Ksh)</P>
                  </TableCell>
                </TableRow>
                <TableRow key='4'>
                  <TableCell style={{ background: '#fe7c19' }}>
                    <P>
                      POD New Customer Orders <br />{' '}
                      <i style={{ fontSize: 10 }}>
                        (new customer who has bought 3 SKUs and worth 500sh)
                      </i>
                    </P>
                  </TableCell>
                  <TableCell style={{ background: '#D3D3D3' }}>
                    <P>{qtyPOD} * 100</P>
                  </TableCell>
                  <TableCell>
                    <P>{totalbonusPOD}</P>
                  </TableCell>
                </TableRow>
                <TableRow key='5'>
                  <TableCell style={{ background: '#fe7c19' }}>
                    <P>
                      PrePaid New Customer Orders <br />{' '}
                      <i style={{ fontSize: 10 }}>
                        (new customer who has bought 3 SKUs and worth 500sh)
                      </i>
                    </P>
                  </TableCell>
                  <TableCell style={{ background: '#D3D3D3' }}>
                    <P>{qtyPrePay} * 200</P>
                  </TableCell>
                  <TableCell>
                    <P>{totalbonus}</P>
                  </TableCell>
                </TableRow>
                <TableRow key='6'>
                  <TableCell style={{ background: 'green' }} colSpan={2}>
                    <H2>Total:</H2>
                  </TableCell>
                  <TableCell style={{ background: 'green' }}>
                    <H2> {total} </H2>
                  </TableCell>
                </TableRow>
                <TableRow key='7'>
                  <TableCell style={{ background: 'red' }} colSpan={2}>
                    <H2>Defaulted Ledger Balance:</H2>
                  </TableCell>
                  <TableCell style={{ background: 'red' }}>
                    <H2> {ledgerBalance} </H2>
                  </TableCell>
                </TableRow>
                <TableRow key='8'>
                  <TableCell style={{ background: 'green' }} colSpan={2}>
                    <H2>Payable Balance:</H2>
                  </TableCell>
                  <TableCell style={{ background: 'green' }}>
                    <H2> {total + ledgerBalance} </H2>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <SearchBar
              value={searched}
              onChange={searchVal => requestSearch(searchVal)}
              onCancelSearch={() => cancelSearch()}
              style={{ marginBottom: '2em' }}
            />
            <TableContainer component={Paper} style={{ maxHeight: 600 }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow
                    style={{
                      height: '5px',
                      width: '100%',
                      backgroundColor: '#d3d3d3',
                    }}
                  >
                    <TableCell>
                      <H2>Pay Out ID</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Order ID</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Cart ID</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Order Amount</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Commission</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Type</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Processed?</H2>
                    </TableCell>
                    <TableCell>
                      <H2>Date</H2>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {commissionArray.map(data => (
                    <TableRow key={data.key}>
                      <TableCell>{data.key}</TableCell>
                      <TableCell>{data.OrderID}</TableCell>
                      <TableCell>{data.CartID}</TableCell>
                      <TableCell>{data.TotalAmount}</TableCell>
                      <TableCell>{data.TotalCommission}</TableCell>
                      <TableCell>
                        {data.Bonus ? <P>Bonus</P> : <P>Commission</P>}
                      </TableCell>
                      <TableCell>
                        {data.Processed ? (
                          <P style={{ color: 'green' }}>True</P>
                        ) : (
                          <P style={{ color: 'red' }}>False</P>
                        )}
                      </TableCell>
                      <TableCell>{data.DateCreated}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Pagination
              style={{
                width: '100%',
                marginTop: '2em',
                justifyContent: 'center',
              }}
              count={count}
              size='large'
              page={page}
              variant='outlined'
              shape='rounded'
              onChange={handlePageClickPagination}
            /> */}
          </>
        )}
        {load()}
      </BodyWrapper>
    )
  }
  return commissions()
}

export default Commissions
