import React, { useState, useContext, useEffect } from 'react'
import { Card, Divider, CardContent, MenuItem } from '@material-ui/core'
import { P, H1, H2, Button, BodyWrapper } from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import 'firebase/firestore'
import FeedbackIcon from '@material-ui/icons/Feedback'
import Fab from '@material-ui/core/Fab'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import DownloadIcon from '@mui/icons-material/Download'
import serializePhoneNumber from '../utils/phone-number'

const Dashboard = () => {
  const [CLOWID, setCLOWID] = useState('')
  const [CLOWName, setCLOWName] = useState('')
  const [CLOWReason, setCLOWReason] = useState('')
  const [news, setNews] = useState([])
  const [customerList, setCustomerList] = useState([])
  const [displayFeedback, setDisplayFeedback] = useState(false)
  const [displaySignUp, setDisplaySignUp] = useState(false)
  const [displayRegisterCL, setDisplayRegisterCL] = useState(false)
  const [displayThankyouNote, setDisplayThankyouNote] = useState(false)
  const [feedback, setFeedback] = useState('')
  const [leaderName, setLeaderName] = useState('')
  const [address, setAddress] = useState('')
  const [pNo, setPNo] = useState('')
  const [email, setEmail] = useState('')
  const [zoneID, setZoneID] = useState('')
  const [address2, setAddress2] = useState('')
  const [dropdownArray, setDropdownArray] = useState([])
  const [rate, setRate] = useState('0')
  const [CName, setCName] = useState('')
  const [clprofile, setClProfile] = useState('Others')
  const [clsource, setClSource] = useState('Others')
  const [clprofileOther, setClProfileOther] = useState('')
  const [clsourceOther, setClSourceOther] = useState('')
  const [ddfee, setDDFee] = useState('')
  const [leaderID, setLeaderID] = useState(
    'CL' + Math.floor(100000000 + Math.random() * 900000000)
  )
  const [CLRequestID, setCLRequestID] = useState(
    'CLR' + Math.floor(100000000 + Math.random() * 900000000)
  )
  const [CEmail, setCEmail] = useState('')
  const [CPNo, setCPNo] = useState('')
  const [CAddress, setCAddress] = useState('')
  const { userState } = useContext(UserContext)
  const db = firebase.firestore()

  const floatingButton = {
    margin: 0,
    top: 80,
    right: 20,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
    zIndex: 1,
  }

  const floatingButton2 = {
    margin: 0,
    bottom: 80,
    right: 20,
    top: 'auto',
    left: 'auto',
    position: 'fixed',
    zIndex: 1,
  }

  const banner = {
    position: 'absolute',
    left: 0,
    backgroundColor: '#efa83d',
    width: '100%',
    textAlign: 'center',
  }

  const card = {
    paddingTop: '170px',
    textAlign: 'center',
  }

  const imageEdit = {
    display: 'block',
    maxWidth: '100%',
  }

  const spacer = {
    flexGrow: 1,
  }

  const row = {
    height: '42px',
    display: 'flex',
    alignItems: 'right',
  }

  useEffect(() => {
    const fetchCustomerData = async () => {
      const data = await db
        .collection('Customers')
        .where('LeaderID', '==', userState.userId)
        .get()

      const customerList = []

      data.forEach(doc => {
        const {
          Address,
          City,
          Email,
          Name,
          Neighborhood,
          PhoneNumber,
          ReferralLink,
          Type,
        } = doc.data()

        customerList.push({
          UID: doc.id,
          Name: Name,
          Address: Address,
          Neighborhood: Neighborhood,
          City: City,
          PhoneNumber: PhoneNumber,
          Email: Email,
          ReferralLink: ReferralLink,
          Type: Type,
        })
      })

      setCustomerList(customerList)
    }

    const fetchNews = async () => {
      let newsItems = []

      const data = await db
        .collection('News')
        .orderBy('Date', 'desc')
        .get()

      data.forEach(doc => {
        console.log('OYAA')

        const { Header, Article, ImgUrl } = doc.data()

        newsItems.push({
          key: doc.id,
          Header: Header,
          Article: Article,
          ImgUrl: ImgUrl,
        })
      })

      setNews(newsItems)
    }

    const fetchZoneDropDown = async () => {
      const data = await db.collection('Zones').get()

      const dropdownList = []

      data.forEach(doc => {
        const { Areas } = doc.data()

        dropdownList.push(
          <MenuItem value={doc.id}>
            {doc.id} : {Areas}
          </MenuItem>
        )
      })

      setDropdownArray(dropdownList)
    }

    const fetchCureentCLOW = async () => {
      const data = await db
        .collection('CommunityLeaders')
        .where('CLOW', '==', true)
        .get()

      data.forEach(doc => {
        console.log('OYAA')

        const { Name, ReasonCLOW } = doc.data()

        setCLOWID(doc.id)
        setCLOWName(Name)
        setCLOWReason(ReasonCLOW)
      })
    }

    fetchCureentCLOW()
    fetchNews()
    fetchZoneDropDown()

    setTimeout(() => {
      setDisplayFeedback(true)
    }, 7000)
  }, [])

  const addFeedback = () => {
    const date = new Date()
    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    const quantities = [
      {
        value: 1,
        label: '1',
      },
      {
        value: 2,
        label: '2',
      },
      {
        value: 3,
        label: '3',
      },
      {
        value: 4,
        label: '4',
      },
      {
        value: 5,
        label: '5',
      },
    ]

    const handleSubmitFeedback = () => {
      if (feedback === '') {
        alert('Please input valid feedback')
      } else if (rate === 0) {
        alert('Please rate us on a scale of 1 to 5')
      } else {
        const firestore = firebase.firestore()

        const fbid = 'CLFB' + Math.floor(100000000 + Math.random() * 900000000)

        firestore
          .collection('CLFeedback')
          .doc(fbid)
          .set({
            UserID: userState.userId,
            Feedback: feedback,
            Rate: rate,
            DateOfFeedback: today,
          })
          .then(() => {
            setDisplayFeedback(false)
            setFeedback('')
            setRate('0')
          })
      }
    }

    const handleCloseCart = () => {
      setDisplayFeedback(false)
    }

    const handleCheck = (event, newValue) => {
      setRate(newValue)
    }

    const valuetext = value => {
      return `${value}`
    }

    const handleRequestCL = () => {
      setDisplayFeedback(false)
      setDisplayRegisterCL(true)
    }

    return (
      <Dialog
        open={displayFeedback}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>Hey!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <H2>Got a minute?</H2>
          <P>
            Please spare a moment to share your feedback about your experience
            shopping with Tushop
          </P>
          <P>How can we do better? Please tell us below:</P>
          <TextareaAutosize
            aria-label='empty textarea'
            name='feedback'
            id='feedback'
            style={{ width: '100%' }}
            rowsMin={3}
            onChange={e => {
              setFeedback(e.target.value)
            }}
            placeholder='Feedback'
            value={feedback}
          />

          <Typography id='discrete-slider-always' gutterBottom>
            <P style={{ textAlign: 'right' }}>Rate us</P>
          </Typography>

          <Slider
            defaultValue={0}
            getAriaValueText={valuetext}
            aria-labelledby='discrete-slider-always'
            step={1}
            min={0}
            max={5}
            onChange={handleCheck}
            marks={quantities}
            valueLabelDisplay='on'
          />

          <P>
            <a onClick={handleRequestCL}>
              <p style={{ color: '#fe7c19', display: 'inline' }}>Click here!</p>
            </a>{' '}
            if you would like to refer others to become community leaders
          </P>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitFeedback}>SUBMIT</Button>

          <Button onClick={handleCloseCart} color='#fe7c19'>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const signUpCLs = () => {
    const handleCloseCart = () => {
      setDisplaySignUp(false)
      setLeaderID('CL' + Math.floor(100000000 + Math.random() * 900000000))
      setLeaderName('')
      setAddress('')
      setPNo('')
      setEmail('')
      setZoneID('')
      setAddress2('')
    }

    const handleChange = e => {
      if (e.target.id === 'name') {
        setLeaderName(e.target.value)
      } else if (e.target.id === 'address') {
        setAddress(e.target.value)
      } else if (e.target.id === 'pNo') {
        setPNo(e.target.value)
      } else if (e.target.id === 'email') {
        setEmail(e.target.value)
      } else if (e.target.name === 'zone') {
        setZoneID(e.target.value)
      } else if (e.target.id === 'locationUrl') {
        setAddress2(e.target.value)
      } else if (e.target.id === 'ddfee') {
        setDDFee(e.target.value)
      }
    }

    const handleSubmitSignUp = () => {
      if (leaderName === '') {
        console.log('MMH! ', address2)
        return alert('Input Name')
      } else if (address === '') {
        return alert('Input Address')
      } else if (leaderID === '') {
        return alert('Input Neighborhood')
      } else if (pNo === '') {
        return alert('Input Phone Number')
      } else if (email === '') {
        return alert('Input Email')
      } else if (address2 === '') {
        return alert('Input Location URL')
      } else if (zoneID === '') {
        return alert('Select Zone')
      } else {
        const date = new Date()

        const today =
          date.getFullYear() +
          '-' +
          (date.getMonth() + 1 > 9
            ? date.getMonth() + 1
            : '0' + (date.getMonth() + 1)) +
          '-' +
          (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
          ' T ' +
          (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
          ':' +
          (date.getMinutes() > 9
            ? date.getMinutes()
            : '0' + date.getMinutes()) +
          ':' +
          (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())

        const CLProfiles = clprofile === 'Others' ? clprofileOther : clprofile
        const CLSources = clsource === 'Others' ? clsourceOther : clsource

        db.collection('CommunityLeaders')
          .where('PhoneNumber', '==', pNo)
          .get()
          .then(data => {
            if (data.empty) {
              db.collection('CommunityLeaders')
                .doc(leaderID)
                .set({
                  Name: leaderName,
                  Address: address,
                  AddressUrl: address2,
                  LeaderID: leaderID,
                  PhoneNumber: pNo,
                  Email: email,
                  Url: 'https://shop.tushop.io/order/' + leaderID,
                  Orders: 0,
                  LedgerBalance: 0,
                  LedgerLimit: -1000,
                  Active: true,
                  Zone: zoneID,
                  Signed: userState.name,
                  DDFee: ddfee,
                  OTP: '0',
                  BA: false,
                  HVA: false,
                  PreSignin: true,
                  PickUp: false,
                  AmountSold: 0,
                  TotalCommission: 0,
                  CurrentCommission: 0,
                  DateCreated: today,
                  KYCUpdated: false,
                  CLProfiles: CLProfiles,
                  CLSources: CLSources,
                })

              alert('Leader Registered')

              handleCloseCart()
            } else {
              alert('Leader already exists. Please sign up a new leader')
            }
          })
      }
    }

    return (
      <Dialog
        open={displaySignUp}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>Sign up</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <P>Supply CL details for registration</P>
          <P>Community Leader ID: {leaderID}</P>
          <TextField
            autoFocus
            margin='dense'
            id='name'
            label='Name'
            type='text'
            fullWidth
            onChange={handleChange}
            value={leaderName}
          />
          <TextField
            autoFocus
            margin='dense'
            id='address'
            label='Address'
            type='text'
            fullWidth
            onChange={handleChange}
            value={address}
          />
          <TextField
            autoFocus
            margin='dense'
            id='pNo'
            label='Phone Number'
            type='email'
            fullWidth
            onChange={handleChange}
            value={pNo}
          />
          <TextField
            autoFocus
            margin='dense'
            id='email'
            label='Email'
            type='email'
            fullWidth
            onChange={handleChange}
            value={email}
          />
          <TextField
            autoFocus
            margin='dense'
            id='ddfee'
            label='Direct Delivery Fee'
            type='text'
            fullWidth
            onChange={handleChange}
            value={ddfee}
          />
          <FormControl fullWidth>
            <InputLabel id='Zonedropdown'>Zone</InputLabel>
            <Select
              labelId='Zonedropdown'
              name='zone'
              onChange={handleChange}
              value={zoneID}
            >
              {dropdownArray}
            </Select>
          </FormControl>

          <FormControl fullWidth style={{ marginTop: '1em' }}>
            <InputLabel id='CLprofile'>CL Profile</InputLabel>
            <Select
              labelId='CLprofile'
              name='clprofile'
              onChange={e => setClProfile(e.target.value)}
              value={clprofile}
            >
              <MenuItem value='Stay at home mum'>Stay at home mum</MenuItem>
              <MenuItem value='Gig economy workers'>
                Gig economy workers
              </MenuItem>
              <MenuItem value='Caretaker / Estate manager'>
                Caretaker / Estate manager
              </MenuItem>
              <MenuItem value='Small bussiness owners e.g barber shop, beauty shop e.t.c'>
                Small bussiness owners e.g barber shop, beauty shop e.t.c
              </MenuItem>
              <MenuItem value='Others'>Others</MenuItem>
            </Select>
          </FormControl>
          {clprofile === 'Others' ? (
            <TextField
              autoFocus
              margin='dense'
              id='clprofileother'
              label='Other - CL Profile'
              type='text'
              onChange={e => setClProfileOther(e.target.value)}
              value={clprofileOther}
            />
          ) : null}

          <FormControl fullWidth style={{ marginTop: '1em' }}>
            <InputLabel id='CLsource'>CL Source</InputLabel>
            <Select
              labelId='CLsource'
              name='clsource'
              onChange={e => setClSource(e.target.value)}
              value={clsource}
            >
              <MenuItem value='Referrals'>Referrals</MenuItem>
              <MenuItem value='Boots on the ground'>
                Boots on the ground
              </MenuItem>
              <MenuItem value='Brand Ambassadors'>Brand Ambassadors</MenuItem>
              <MenuItem value='Estate Activation'>Estate Activation</MenuItem>
              <MenuItem value='Others'>Others</MenuItem>
            </Select>
          </FormControl>
          {clsource === 'Others' ? (
            <TextField
              autoFocus
              margin='dense'
              id='clsourceother'
              label='Other - CL Source'
              type='text'
              onChange={e => setClSourceOther(e.target.value)}
              value={clsourceOther}
            />
          ) : null}
          <TextField
            autoFocus
            margin='dense'
            id='locationUrl'
            label='location URL'
            type='text'
            fullWidth
            onChange={handleChange}
            value={address2}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitSignUp}>SUBMIT</Button>

          <Button onClick={handleCloseCart} color='#fe7c19'>
            CANCEL
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const CLRequest = () => {
    const firestore = firebase.firestore()

    const date = new Date()
    const today =
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : '0' + (date.getMonth() + 1)) +
      '-' +
      (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
      ' T ' +
      (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) +
      ':' +
      (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes())

    const handleCloseDialog = () => {
      setDisplayRegisterCL(false)
    }

    const handleSubmitCLRequest = () => {
      if (CName === '') {
        alert('Input Valid Name')
      } else if (CEmail === '') {
        alert('Input Valid Email')
      } else if (CPNo === '') {
        alert('Input Valid Phone Number')
      } else if (CAddress === '') {
        alert('Input Valid Address')
      } else {
        firestore
          .collection('CLRequests')
          .doc(CLRequestID)
          .set({
            Name: CName,
            Email: CEmail,
            PhoneNumber: CPNo,
            Address: CAddress,
            Date: today,
            Referral: true,
          })
          .then(() => {
            setDisplayRegisterCL(false)
            setDisplayThankyouNote(true)
          })
      }
    }

    return (
      <Dialog
        open={displayRegisterCL}
        onClose={handleCloseDialog}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>CL Sign up!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <P>Enter their details below and submit your request</P>
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => setCName(e.target.value)}
            name='Name'
            placeholder='Name'
            value={CName}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => setCEmail(e.target.value)}
            name='Email'
            placeholder='Email'
            value={CEmail}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => setCPNo(e.target.value)}
            name='Phone Number'
            placeholder='Phone Number'
            value={CPNo}
            variant='standard'
          />
          <TextField
            id='standard-basic'
            style={{ width: '100%', marginTop: '20px' }}
            onChange={e => setCAddress(e.target.value)}
            name='Address'
            placeholder='Address'
            value={CAddress}
            variant='standard'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitCLRequest}>SUBMIT</Button>

          <Button onClick={handleCloseDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const postCLRequest = () => {
    const handleCloseDialog = () => {
      setDisplayThankyouNote(false)
    }

    return (
      <Dialog
        open={displayThankyouNote}
        onClose={handleCloseDialog}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          {' '}
          <H1>
            <p style={{ color: '#fe7c19' }}>Complete!</p>
          </H1>
        </DialogTitle>
        <DialogContent>
          <H2>Thank you for your interest</H2>
          <P>
            We are reviewing the details and will contact you shortly for the
            final stage of registration.
          </P>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const bannerr = () => {
    const link = 'https://shop.tushop.io/order/' + userState.userId
    return (
      <div style={banner}>
        <H1>
          Welcome <br />
          {userState.name.split(' ')[0]}
        </H1>
        <P>Leader ID: {userState.userId} </P>
        <P>
          Leader Link:{' '}
          <a href={link} target='_blank'>
            {link}
          </a>
        </P>
      </div>
    )
  }

  const dashboard = () => {
    const download = (e, imgUrl) => {
      fetch(imgUrl, {
        method: 'GET',
        headers: {
          'Access-Control-Allow-Origin': 'http://localhost:3002',
        },
      })
        .then(resp => {
          console.log(resp)
        })
        .catch(error => {
          console.log(error)
        })

      // download image directly via url
      // const xhr = new XMLHttpRequest();
      // xhr.responseType = 'blob';
      // xhr.onload = (event) => {
      //     const blob = xhr.response;
      //     //create a file from the returned blob
      // };
      // xhr.open('GET', imgUrl);
      // xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
      // xhr.send();
    }

    return (
      <BodyWrapper>
        <Fab
          onClick={e => setDisplayFeedback(true)}
          color='primary'
          style={floatingButton}
        >
          <FeedbackIcon />
        </Fab>
        {addFeedback()}
        {CLRequest()}
        {postCLRequest()}
        {signUpCLs()}
        {userState.BA ? (
          <Fab
            variant='extended'
            onClick={e => setDisplaySignUp(true)}
            color='primary'
            style={floatingButton2}
          >
            <P>Sign up CLs</P>
          </Fab>
        ) : (
          <></>
        )}
        {bannerr()}

        <div style={card}>
          <Card style={{ backgroundColor: '#E5E4E2' }}>
            <CardContent>
              <H1>
                <p style={{ lineHeight: '1', color: '#fe7c19' }}>
                  🥇
                  <br />
                  CL of the Week!
                  <br />
                  🥳
                </p>
              </H1>
              <H1>
                <p style={{ lineHeight: '1' }}>{CLOWName}</p>
              </H1>
              <H2>
                <p style={{ lineHeight: '1' }}>{CLOWID}</p>
              </H2>
              <P>
                <b>Reason: </b>
                {CLOWReason}
              </P>
            </CardContent>
          </Card>
          <Divider />
          <br />
          {news.length === 0 ? (
            <></>
          ) : (
            <>
              <Card style={{ backgroundColor: '#232f3e' }}>
                <H1>
                  <p style={{ color: '#fe7c19' }}>Tushop News</p>
                </H1>
              </Card>
              {news.map(data => (
                <>
                  <Card style={{ backgroundColor: '#E5E4E2' }}>
                    <CardContent>
                      <H2>{data.Header}</H2>
                      <span style={spacer} />
                      <Divider />
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <img src={data.ImgUrl} style={imageEdit} />
                      </div>
                      <Divider />
                      <P>{data.Article}</P>
                    </CardContent>
                  </Card>
                  <br />
                </>
              ))}
            </>
          )}
        </div>
      </BodyWrapper>
    )
  }
  return dashboard()
}

export default Dashboard
