import React, { useState } from "react"
import { BodyWrapper } from "../components"
import { Document, Page } from "react-pdf/dist/esm/entry.webpack"
import TermsDoc from "../resources/docs/TERMS AND CONDITIONS OF USE OF THE TUSHOP PLATFORM FOR COMMUNITY LEADERS.pdf"

const TermsCondition = () => {
  const [numPages, setNumPages] = useState(null)
  // const [pageNumber, setPageNumber] = useState(1)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }
  return (
    <BodyWrapper style={{ padding: 0, margin: 0 }}>
      <Document
        style={{ padding: 0, margin: 0 }}
        loading="please wait"
        file={TermsDoc}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page scale={0.7} key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
    </BodyWrapper>
  )
}

export default TermsCondition
