import React, { useState, useContext, useEffect } from "react"
import { Card, Divider, CardContent, MenuItem, Dialog, DialogContent, DialogActions, Table, TableHead, TableCell, TableBody, TableRow } from "@material-ui/core"
import { P, H1, H2, Button, BodyWrapper } from "../components"
import { UserContext } from "../contexts/userContext"
import firebase from "../firebase.js"
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

const Commissions = (props) => {
  const [referralCode, setReferralCode] = useState("RF" + Math.floor(100000000 + Math.random() * 900000000))
  const [processed, setProcessed] = useState(false)
  const [firstLeaderName, setFirstLeaderName] = useState('')
  const [LastLeaderName, setLastLeaderName] = useState('')
  const [address, setAddress] = useState('')
  const [email, setEmail] = useState('')
  const [pNo, setPNo] = useState('')
  const [zoneID, setZoneID] = useState('')
  const [address2, setAddress2] = useState('')
  const [noReferrals, setNoReferrals] = useState(false)
  const [Complete, setComplete] = useState(false)
  const [dropdownArray, setDropdownArray] = useState([])
  const [leaderID, setLeaderID] = useState("")
  const [leaderName, setLeaderName] = useState("")
  const [leaderPNo, setLeaderPNo] = useState("")
  const [refresh, setRefresh] = useState(0)
  const { userState } = useContext(UserContext)

  const db = firebase.firestore()

  useEffect(() => {

    const fetchData = () => {

      db.collection("CLReferrals").doc(props.match.params.referralCode).get().then((doc) => {
        if (doc.exists) {
          const { Processed, LeaderID } = doc.data()

          if (Processed) {
            setProcessed(true)
          } else {

            fetchLeaderData(LeaderID)
          }

        } else {

          setNoReferrals(true)

        }
      })

    }

    const fetchLeaderData = (LeaderID) => {

      setLeaderID(LeaderID)

      db.collection("CommunityLeaders").doc(LeaderID).get().then((doc) => {
        if (doc.exists) {
          const { Name, PhoneNumber } = doc.data()


          setLeaderName(Name)
          setLeaderPNo(PhoneNumber)

        } else {

          setNoReferrals(true)

        }
      })

    }

    const fetchZoneDropDown = async () => {

      const data = await db.collection("Zones").get()

      const dropdownList = []

      data.forEach((doc) => {

        const { Areas } = doc.data()

        dropdownList.push(<MenuItem value={doc.id}>{doc.id} : {Areas}</MenuItem>)


      })

      setDropdownArray(dropdownList)

    }

    fetchData()
    fetchZoneDropDown()

  }, [refresh])


  const commissions = () => {

    const handleChange = (e) => {

      if (e.target.id === "firstName") {
        setFirstLeaderName(e.target.value)
      } else if (e.target.id === "lastName") {
        setLastLeaderName(e.target.value)
      } else if (e.target.id === "address") {
        setAddress(e.target.value)
      } else if (e.target.id === "email") {
        setEmail(e.target.value)
      } else if (e.target.id === "pNo") {
        setPNo(e.target.value)
      } else if (e.target.name === "zone") {
        setZoneID(e.target.value)
      } else if (e.target.id === "locationUrl") {
        setAddress2(e.target.value)
      }
    }

    const handleSubmitSignUp = () => {

      if (firstLeaderName === "") {
        return (
          alert("Please Enter First Name")
        )
      } else if (LastLeaderName === "") {
        return (
          alert("Please Enter Last Name")
        )
      } else if (address === "") {
        return (
          alert("Please Enter Address")
        )
      } else if (email === "") {
        return (
          alert("Please Enter Email Address")
        )
      } else if (pNo === "") {
        return (
          alert("Please Enter Phone Number")
        )
      } else if (address2 === "") {
        return (
          alert("Please Enter Location URL")
        )
      } else if (zoneID === "") {
        return (
          alert("Select Zone")
        )
      } else {

        const date = new Date()

        const today = date.getFullYear() +
          '-' +
          ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) +
          '-' +
          (date.getDate() > 9 ? (date.getDate()) : '0' + (date.getDate())) +
          ' T ' +
          (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours())) +
          ":" +
          (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes())) +
          ":" +
          (date.getSeconds() > 9 ? (date.getSeconds()) : '0' + (date.getSeconds()))

        db.collection("CommunityLeaders").where("PhoneNumber", "==", pNo).get().then((data) => {
          if (data.empty) {

            db.collection('CLReferrals').doc(props.match.params.referralCode).update({
              Processed: true,
              Name: firstLeaderName + " " + LastLeaderName,
              Address: address,
              AddressUrl: address2,
              LeaderID: leaderID,
              PhoneNumber: pNo,
              Email: email,
              Orders: 0,
              LedgerBalance: 0,
              LedgerLimit: -1000,
              Active: true,
              Zone: zoneID,
              Signed: props.match.params.referralCode,
              OTP: "0",
              BA: false,
              Registered: false,
              PreSignin: true,
              PickUp: false,
              AmountSold: 0,
              TotalCommission: 0,
              CurrentCommission: 0,
              DateUpdated: today
            }).then(() => {

              alert("Registration Complete")

              setComplete(true)

            })

          } else {

            alert("Community Leader already exists.")

          }
        })

      }

    }

    return (
      <BodyWrapper>
        <H1>Welcome to Tushop</H1>
        {noReferrals ?
          <H2>Sorry, the referral code does not exists</H2>
          :
          <>
            {processed ?
              <H2>Sorry, The referral code has already been used. Please ask Community leader to send you a new one</H2>
              :
              <>

                <H2>Referral Code Active</H2>
                <P>Leader: {leaderName}</P>
                <P>Phone Number: {leaderPNo}</P>
                <br />
                <P>Supply CL details for registration</P>
                <TextField
                  autoFocus
                  margin="dense"
                  id="firstName"
                  label="First Name"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={firstLeaderName}
                />

                <TextField
                  autoFocus
                  margin="dense"
                  id="lastName"
                  label="Last Name"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={LastLeaderName}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="address"
                  label="Address"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={address}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="E-Mail"
                  type="email"
                  fullWidth
                  onChange={handleChange}
                  value={email}
                />
                <TextField
                  autoFocus
                  margin="dense"
                  id="pNo"
                  label="Phone Number (07xx/01xx)"
                  inputProps={{ maxLength: 10 }}
                  type="email"
                  fullWidth
                  onChange={handleChange}
                  value={pNo}
                />
                <FormControl fullWidth>
                  <InputLabel id="Zonedropdown">Zone</InputLabel>
                  <Select
                    labelId="Zonedropdown"
                    name="zone"
                    onChange={handleChange}
                    value={zoneID}
                  >
                    {dropdownArray}
                  </Select>
                </FormControl>
                <TextField
                  autoFocus
                  margin="dense"
                  id="locationUrl"
                  label="location URL"
                  type="text"
                  fullWidth
                  onChange={handleChange}
                  value={address2}
                />

                <Button onClick={handleSubmitSignUp}>
                  SUBMIT
                </Button>

              </>
            }
          </>

        }
      </BodyWrapper>
    )
  }

  const completed = () => {

    return (
      <BodyWrapper>
        <H1>Complete!</H1>
        <P>Thank you for using the referral code to register. the CL Ops team will reach out to you for final stages of registration shortly.</P>
        <P>Thank you for your patience</P>
      </BodyWrapper>
    )

  }

  if (Complete) {
    return completed()
  }
  return commissions()

}

export default Commissions
