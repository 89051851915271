import React, { useState, useContext, useEffect } from 'react'
import { H1, BodyWrapper, P } from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Switch from '@material-ui/core/Switch'
import { Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Pagination from '@material-ui/lab/Pagination'
import usePagination from '../utils/Pagination'
import SearchBar from 'material-ui-search-bar'

const PendingOrders = () => {
  const { userState } = useContext(UserContext)
  const [loadingtable, setLoadingTable] = useState(true)
  const [pendingOrderList, setPendingOrderList] = useState([])
  const [carts, setCarts] = useState([])
  const [displayCart, setDisplayCart] = useState(false)
  const [cartID, setCartID] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [grandTotal, setGrandTotal] = useState('')
  const [cartItems, setCartItems] = useState([])
  const [searched, setSearched] = useState('')

  const db = firebase.firestore()
  const realtime = firebase.database()

  //handle paginaion events
  const [page, setPage] = useState(1)

  const PER_PAGE = 300

  const count = Math.ceil(pendingOrderList.length / PER_PAGE)
  const _DATA = usePagination(pendingOrderList, PER_PAGE)

  const handlePageClickPagination = (e, p) => {
    setPage(p)
    _DATA.jump(p)
  }

  useEffect(() => {
    const fetchOrderData = async () => {
      setLoadingTable(true)
      const data = await db
        .collection('Orders')
        .where('Delivered', '==', false)
        .where('LeaderID', '==', userState.userId)
        .orderBy('DateOfOrder', 'asc')
        .get()

      const pendingList = []

      const cartList = []

      data.forEach(doc => {
        const {
          CustomerID,
          CustomerName,
          CartID,
          CustomerPNo,
          DeliveryAddress,
          LeaderID,
          LeaderName,
          TotalPrice,
          DateOfOrder,
          CDelivery,
          POD,
          Return,
        } = doc.data()
        pendingList.push({
          key: doc.id,
          customerID: CustomerID,
          customerName: CustomerName,
          cartID: CartID,
          pNo: CustomerPNo,
          address: DeliveryAddress,
          leaderID: LeaderID,
          leaderName: LeaderName,
          Total: TotalPrice,
          date: DateOfOrder,
          delivered: CDelivery,
          pod: POD,
          return: Return,
        })

        const shoppingCartRef = realtime.ref('shoppingCarts/' + CartID)
        shoppingCartRef.on('value', snapshot => {
          if (snapshot.exists()) {
            snapshot.forEach(function(childSnapshot) {
              if (childSnapshot.key !== 'grandTotal') {
                const data = childSnapshot.val()

                cartList.push({
                  orderID: doc.id,
                  productDesc: data.productDesc,
                  productPrice: data.productPrice,
                  quantity: data.quantity,
                  total: data.total,
                })
              }
            })

            // Code for adding grandtotal to cart record
          } else {
            console.log('No data available')
            // this.setState({
            //   emptyCart: true
            // })
            setLoadingTable(false)
          }
        })
      })
      setCarts(cartList)

      setTimeout(() => {
        setPendingOrderList(pendingList)
        setLoadingTable(false)
      }, 1000)

      console.log(cartList)
    }

    fetchOrderData()
  }, [refresh])

  //filter search request
  const requestSearch = searchedVal => {
    if (searchedVal !== '') {
      const filteredRows = pendingOrderList.filter(row => {
        let nameSearch = row.customerName
        let phoneNumberSearch = row.pNo
        let dateSearch = row.date
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(nameSearch) ||
          expression.test(phoneNumberSearch) ||
          expression.test(dateSearch) ||
          expression.test(null)
        )
      })
      setPendingOrderList(filteredRows)
    } else {
      setRefresh(refresh + 1)
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setRefresh(refresh + 1)
    }
  }
  const handleChangeActivity = e => {
    e.preventDefault()

    const OrderRef = db.collection('Orders').doc(e.target.name)

    OrderRef.update({
      CDelivery: e.target.checked,
    })
      .then(() => {
        console.log('Document successfully updated!')
        setRefresh(refresh + 1)
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error)
      })
  }

  const handleDisplayCart = (e, cartID, Total) => {
    const fetchData = async () => {
      const data = await db.collection('Products').get()

      const listCart = []

      data.forEach(doc => {
        const { Name, Description, ImgUrl, Price } = doc.data()

        if (cartID.charAt(0) === 'C') {
          const shoppingCartRef = realtime.ref(
            'shoppingCarts/' + cartID + '/' + doc.id
          )
          shoppingCartRef.on('value', snapshot => {
            const data = snapshot.val()

            if (snapshot.exists()) {
              listCart.push({
                key: doc.id,
                name: Name,
                desc: Description,
                imgUrl: ImgUrl,
                price: Price,
                quantity: data.quantity,
                total: data.total,
              })

              // setFormState({
              //   ...formState,
              //     displayCart: true!,
              //     cartItems: listCart,
              //     cartID: cartID,
              //     grandTotal: Total
              // })

              setCartItems(listCart)
              setGrandTotal(Total)
              setCartID(cartID)

              // Code for adding grandtotal to cart record
            } else {
              console.log('No data available')
              // this.setState({
              //   emptyCart: true
              // })
            }
          })
        } else if (cartID.charAt(0) === 'G') {
          db.collection('GBQueue')
            .doc(cartID)
            .get()
            .then(doc => {
              const { ProductID, Quantity } = doc.data()

              db.collection('Products')
                .doc(ProductID)
                .get()
                .then(doc2 => {
                  const { Description, Price } = doc2.data()

                  listCart.push({
                    key: doc.id,
                    desc: Description,
                    price: Price,
                    quantity: Quantity,
                    total: (parseInt(Price) * parseInt(Quantity)).toString(),
                  })

                  setCartItems(listCart)
                  setGrandTotal(Total)
                  setCartID(cartID)
                })
            })
        }
      })

      setDisplayCart(true)
    }
    fetchData()
  }

  const handleCloseCart = () => {
    setDisplayCart(false)
    setCartID('')
    setCartItems([])
    setGrandTotal('')
  }

  const DialogCart = () => {
    return (
      <Dialog
        open={displayCart}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>CartID: {cartID}</DialogTitle>

        <DialogContent>
          <Table aria-label='simple table'>
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell>
                  <b>
                    <h2>Description</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Price</h2>
                  </b>
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>
                    <h2>Qty</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <b>
                    <h2>Total</h2>
                  </b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cartItems.map(data => (
                <TableRow key='row1'>
                  <TableCell>{data.desc}</TableCell>
                  <TableCell>{data.price}</TableCell>
                  <TableCell>X</TableCell>
                  <TableCell>{data.quantity}</TableCell>
                  <TableCell>
                    <b>{data.total}</b>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow key='row2'>
                <TableCell colSpan={3}>
                  <h1>GrandTotal</h1>
                </TableCell>
                <TableCell colSpan={2}>
                  <h1>{grandTotal}</h1>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const pendingOrders = () => {
    return (
      <BodyWrapper>
        {DialogCart()}
        <H1>
          <b>Pending Orders:</b> {pendingOrderList.length}
        </H1>
        <SearchBar
          value={searched}
          onChange={searchVal => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{ marginBottom: '2em' }}
        />

        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Customer Name</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Phone Number</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Delivery Address</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Date</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Status</P>
                </TableCell>
                <TableCell style={{ background: '#fe7c19' }}>
                  <P>Delivered?</P>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loadingtable === false ? (
                _DATA.currentData().map(data => {
                  return (
                    <>
                      <TableRow key={data.key}>
                        <TableCell>{data.customerName}</TableCell>
                        <TableCell>{data.pNo}</TableCell>
                        <TableCell>{data.address}</TableCell>
                        <TableCell>{data.date}</TableCell>
                        <TableCell> {data.pod ? 'POD' : 'Paid'}</TableCell>
                        <TableCell>
                          <Switch
                            checked={data.delivered}
                            onChange={e => handleChangeActivity(e)}
                            name={data.key}
                            color='primary'
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <TableContainer
                            component={Paper}
                            style={{ maxHeight: 500 }}
                          >
                            <P>CART ID: {data.cartID}</P>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ background: '#fe7c19' }}>
                                    <P>Product Name</P>
                                  </TableCell>
                                  <TableCell style={{ background: '#fe7c19' }}>
                                    <P>Price</P>
                                  </TableCell>
                                  <TableCell style={{ background: '#fe7c19' }}>
                                    <P>Quantity</P>
                                  </TableCell>
                                  <TableCell style={{ background: '#fe7c19' }}>
                                    <P>Total</P>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {carts.map(dataCart => {
                                  if (dataCart.orderID === data.key) {
                                    return (
                                      <TableRow>
                                        <TableCell>
                                          {dataCart.productDesc}
                                        </TableCell>
                                        <TableCell>
                                          {dataCart.productPrice}
                                        </TableCell>
                                        <TableCell>
                                          {dataCart.quantity}
                                        </TableCell>
                                        <TableCell>{dataCart.total}</TableCell>
                                      </TableRow>
                                    )
                                  }
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </TableCell>
                      </TableRow>
                    </>
                  )
                })
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={8}
                    style={{ textAlign: 'center', justifyContent: 'center' }}
                  >
                    <CircularProgress color='#fe8f19' />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{
            width: '100%',
            marginTop: '2em',
            justifyContent: 'center',
          }}
          count={count}
          size='large'
          page={page}
          variant='outlined'
          shape='rounded'
          onChange={handlePageClickPagination}
        />
      </BodyWrapper>
    )
  }
  return pendingOrders()
}

export default PendingOrders
