import React, { useState, useContext, useEffect } from 'react'
import {
  H1,
  H2,
  Spinner,
  BodyWrapper,
  P,
  Button,
  CenteredDiv,
} from '../components'
import { UserContext } from '../contexts/userContext'
import firebase from '../firebase.js'
import 'firebase/firestore'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { Checkmark } from 'react-checkmark'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import SearchBar from 'material-ui-search-bar'

const MyCustomers = () => {
  const [loading, setLoading] = useState(false)
  const [markedAll, setMarkedAll] = useState(false)
  const [customerList, setCustomerList] = useState([])
  const [ledger, setLedger] = useState(0)
  const [ledgerSelect, setLedgerSelect] = useState([])
  const [ledgerLimit, setLedgerLimit] = useState(0)
  const [ledgerOverdue, setLedgerOverdue] = useState(0)
  const [ledgerTable, setLedgerTable] = useState([])
  const [refresh, setRefresh] = useState(0)
  const [requestSent, setRequestSent] = useState(false)
  const [Paid, setPaid] = useState(false)
  const [openDisplayLedger, setOpenDisplayLedger] = useState(false)
  const [openPaymentPage, setOpenPaymentPage] = useState(false)
  const [openPaymentStatus, setOpenPaymentStatus] = useState(false)
  const [Cancelled, setCancelled] = useState(false)
  const [LQID, setLQID] = useState('')
  const [CustomerID, setCustomerID] = useState('')
  const [searched, setSearched] = useState('')
  const [downtime500, setDowntime500] = useState(false)
  const [downtime400, setDowntime400] = useState(false)

  const [total, setTotal] = useState(0)
  const { userState } = useContext(UserContext)
  const db = firebase.firestore()

  useEffect(() => {
    setRequestSent(false)
    const fetchCustomerData = async () => {
      const yesterdate = new Date()

      yesterdate.setDate(yesterdate.getDate() - 3)

      const threeDaysAgo =
        yesterdate.getFullYear() +
        '-' +
        (yesterdate.getMonth() + 1 > 9
          ? yesterdate.getMonth() + 1
          : '0' + (yesterdate.getMonth() + 1)) +
        '-' +
        (yesterdate.getDate() > 9
          ? yesterdate.getDate()
          : '0' + yesterdate.getDate()) +
        ' T ' +
        (yesterdate.getHours() > 9
          ? yesterdate.getHours()
          : '0' + yesterdate.getHours()) +
        ':' +
        (yesterdate.getMinutes() > 9
          ? yesterdate.getMinutes()
          : '0' + yesterdate.getMinutes())

      const data = await db
        .collection('Ledger')
        .where('Hold', '==', false)
        .where('LeaderID', '==', userState.userId)
        .where('Paid', '==', false)
        .orderBy('Date', 'asc')
        .get()

      const list = []

      let overDue = 0

      data.forEach(doc => {
        const { OrderID, CustomerID, LeaderID, Total, Paid, Date } = doc.data()

        if (Date <= threeDaysAgo) {
          list.push({
            mark: false,
            key: doc.id,
            orderID: OrderID,
            total: Total,
            Paid: Paid,
            customerID: CustomerID,
            leaderID: LeaderID,
            date: Date,
            color: '#E3735E',
          })

          overDue = overDue - Total
        } else {
          list.push({
            mark: false,
            key: doc.id,
            orderID: OrderID,
            total: Total,
            Paid: Paid,
            customerID: CustomerID,
            leaderID: LeaderID,
            date: Date,
            color: 'green',
          })
        }
      })

      setCustomerList(list)
      setLedgerOverdue(overDue)
    }

    const fetchLedgerData = async () => {
      const data = await db.collection('CommunityLeaders').doc(userState.userId)

      const list = []

      data.get().then(doc => {
        const { LedgerBalance, LedgerLimit } = doc.data()

        setLedger(LedgerBalance)
        setLedgerLimit(LedgerLimit)
      })
    }

    fetchCustomerData()
    fetchLedgerData()
  }, [refresh])

  const displayLedger = async (e, userID) => {
    const data = await db
      .collection('Ledger')
      .where('CustomerID', '==', userID)
      .where('LeaderID', '==', userState.userId)
      .where('Paid', '==', false)
      .orderBy('Date', 'asc')

    const ledgerList = []

    data.get().then(doc => {
      if (!doc.empty) {
        doc.forEach(data => {
          const { OrderID, CustomerID, Total, Paid, Date } = data.data()

          ledgerList.push({
            key: data.id,
            orderID: OrderID,
            total: Total,
            Paid: Paid,
            customerID: CustomerID,
            date: Date,
          })

          setLedgerTable(ledgerList)
          setCustomerID(userID)

          console.log(ledgerList)
        })

        setOpenDisplayLedger(true)
      } else {
        alert('Ledger is empty')
      }
    })
  }

  const settleLedger = async e => {
    const array = []

    let amount = 0
    await customerList.forEach(data => {
      if (data.mark) {
        array.push({ LGID: data.key, amount: data.total })
        setLedgerSelect(array)
        amount = amount + data.total
      }
    })
    setOpenPaymentStatus(true)
    setTotal(amount)

    // const url = "https://api.tushop.io/api/v1/sendQuikkSTKPushLedger";

    // fetch(url,
    //   {
    //     method: 'POST',
    //     headers: {
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify({
    //       leaderID: userState.userId,
    //       leaderPNo: fullNo,
    //       customerID: customerID,
    //       amount: ledgerBalance,
    //       orderID: orderID
    //     })
    //   }
    // ).then(response => {
    //   if (response.status === 201) {
    //     return response.json()
    //   } else {
    //     throw new Error('Something went wrong on api server!');
    //   }
    // }).then(response => {
    //   setLQID(response.LQID)
    //   setRequestSent(true)
    // }).catch(error => {
    //   console.error(error);
    // });
  }

  const handleDisplayCustomer = async (e, UID) => {
    console.log('ellow')

    let list = []
    await db
      .collection('Customers')
      .doc(UID)
      .get()
      .then(doc => {
        if (doc.exists) {
          console.log('OLAAAA')

          const {
            Name,
            LeaderID,
            Address,
            Neighborhood,
            City,
            PhoneNumber,
            Email,
            ReferralLink,
          } = doc.data()

          list.push({
            UID: UID,
            Name: Name,
            LeaderID: LeaderID,
            Address: Address,
            Neighborhood: Neighborhood,
            City: City,
            PhoneNumber: PhoneNumber,
            Email: Email,
            ReferralLink: ReferralLink,
          })
        }
      })
      .then(() => {
        console.log('PIMBI!')
        setOpenDisplayLedger(true)
        setLedgerTable(list)
      })
  }

  //filter search request
  const requestSearch = searchedVal => {
    if (searchedVal !== '') {
      const filteredRows = customerList.filter(row => {
        let keySearch = row.key
        let orderSearch = row.orderID
        let leaderSearch = row.leaderID
        let customerSearch = row.customerID
        let expression = new RegExp(searchedVal, 'gi')
        return (
          expression.test(keySearch) ||
          expression.test(orderSearch) ||
          expression.test(leaderSearch) ||
          expression.test(customerSearch) ||
          expression.test(null)
        )
      })
      setCustomerList(filteredRows)
    } else {
      setRefresh(refresh + 1)
    }
  }
  //cancel search
  const cancelSearch = () => {
    setSearched('')
    if (searched === '' || searched.trim().length < 1) {
      console.log('OOP, nothing in your search bar')
      setRefresh(refresh + 1)
    }
  }

  const customer = () => {
    const handleCloseCart = () => {
      setOpenDisplayLedger(false)
      setLedgerTable([])
    }

    return (
      <Dialog
        open={openDisplayLedger}
        onClose={handleCloseCart}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>
          <H2>Customer Info</H2>
        </DialogTitle>

        <DialogContent>
          {ledgerTable.map(data => (
            <ul>
              <H2>UID: {data.UID}</H2>
              <H2>Name: {data.Name}</H2>
              <H2>Leader ID: {data.LeaderID}</H2>
              <H2>Address: {data.Address}</H2>
              <H2>Neighborhood: {data.Neighborhood}</H2>
              <H2>City: {data.City}</H2>
              <H2>Phone Number: {data.PhoneNumber}</H2>
              <H2>Email: {data.Email}</H2>
            </ul>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCart}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const paymentPage = () => {
    const handleClosePaymentStatus = () => {
      setOpenPaymentStatus(false)
      setOpenDisplayLedger(false)
      setPaid(false)
      setLQID('')
      setCancelled(false)
      setRequestSent(false)
      setOpenPaymentPage(false)

      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    return (
      <Dialog
        open={openPaymentPage}
        onClose={handleClosePaymentStatus}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent>
          {!requestSent ? (
            <>
              <H2>Sending M-pesa payment Request</H2>
              <H2>Please wait.</H2>
            </>
          ) : (
            <>
              {!Cancelled ? (
                <>
                  {!Paid ? (
                    <CenteredDiv vertical>
                      <Spinner large secondary />
                      <H2 style={{ display: 'flex' }}>
                        Awaiting Payment confirmation
                      </H2>
                    </CenteredDiv>
                  ) : (
                    <>
                      <Checkmark size='xxLarge' />
                      <H2>Ledger Settled!</H2>
                    </>
                  )}
                </>
              ) : (
                <>
                  <CancelOutlinedIcon
                    style={{ color: 'red', fontSize: '700%' }}
                  />
                  <H2>Payment Cancelled!</H2>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentStatus}>CANCEL</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const paymentStatus = () => {
    const handleClosePaymentStatus = () => {
      setOpenPaymentStatus(false)
      setOpenDisplayLedger(false)
      setPaid(false)
      setCancelled(false)
      setRequestSent(false)
      downtime500 === true ? setDowntime500(true) : setDowntime500(false)
      downtime400 === true ? setDowntime400(true) : setDowntime400(false)
    }

    const handleOpenPaymentPage = () => {
      setOpenPaymentPage(true)

      let fullNo = ''

      if (userState.pNo.length === 10) {
        fullNo = '254' + userState.pNo.substring(1)
      } else {
        fullNo = userState.pNo
      }

      const url = 'https://api.tushop.io/api/v1/sendQuikkSTKPushLedger'

      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          leaderID: userState.userId,
          leaderPNo: fullNo,
          amount: total,
          ledgers: ledgerSelect,
        }),
      })
        .then(response => {
          if (response.status === 201) {
            return response.json()
          } else if (response.status === 500) {
            setDowntime500(true)
            setTimeout(() => {
              alert(
                'Session timeout, please cancel payment request and refresh'
              )
            }, 4000)
          } else if (response.status === 400) {
            setDowntime400(true)
            setTimeout(() => {
              alert('Transaction failed')
            }, 4000)
          } else {
            throw new Error('Something went wrong on api server!')
          }
        })
        .then(response => {
          setLQID(response.LQID)

          setRequestSent(true)
        })
        .catch(error => {
          console.error(error)
        })
    }
    return (
      <Dialog
        open={openPaymentStatus}
        onClose={handleClosePaymentStatus}
        aria-labelledby='form-dialog-title'
      >
        <DialogContent>
          <div style={{ placeItems: 'center', color: 'red' }}>
            {downtime500 === true ? (
              <>
                <H2 style={{ textAlign: 'center' }}>
                  Session timeout, please cancel payment request and refresh{' '}
                </H2>
              </>
            ) : null}
            {downtime400 === true ? (
              <H2 style={{ textAlign: 'center' }}>Transaction failed </H2>
            ) : null}
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <H2>LGID</H2>
                </TableCell>
                <TableCell>
                  <H2>Amount</H2>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ledgerSelect.map(data => (
                <TableRow>
                  <TableCell>
                    <P>{data.LGID}</P>
                  </TableCell>
                  <TableCell>
                    <P>{data.amount}</P>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <H2>Total: </H2>
                </TableCell>
                <TableCell>
                  <H2>{total}</H2>
                </TableCell>
              </TableRow>
              <TableRow>
                {ledgerSelect.length > 1 ? (
                  <TableCell colSpan={2}>
                    <H2>Settle all {ledgerSelect.length} Ledgers?</H2>
                  </TableCell>
                ) : (
                  <TableCell colSpan={2}>
                    <H2>Settle Ledger?</H2>
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePaymentStatus}>CANCEL</Button>
          <Button onClick={handleOpenPaymentPage}>SETTLE</Button>
        </DialogActions>
      </Dialog>
    )
  }

  const myCustomers = () => {
    const handleAddAllLedgers = async (e, arrayLedgers) => {
      setMarkedAll(true)

      setLoading(true)

      e.preventDefault()

      if (e.target.checked) {
        await arrayLedgers.forEach(data => {
          data.mark = true
        })

        setLoading(false)
      } else {
        setMarkedAll(false)
        setLedgerSelect([])
        setLoading(false)
        setTotal(0)

        await arrayLedgers.forEach(data => {
          data.mark = false
        })

        console.log(total)
      }
    }

    const handleAddLedger = async (e, LGID) => {
      setLoading(true)

      e.preventDefault()

      if (e.target.checked) {
        await customerList.forEach(data => {
          if (data.key === LGID) {
            data.mark = true
          }
        })

        setLoading(false)
      } else {
        await customerList.forEach(data => {
          if (data.key === LGID) {
            data.mark = false
          }
        })

        if (markedAll) {
          setMarkedAll(false)
        }

        setLoading(false)
      }
    }
    //console.log('I want to know the customers list', customerList)
    if (requestSent && !Paid && !Cancelled) {
      setInterval(async () => {
        console.log('OLAAAA')
        const data = await db.collection('LedgerQueue').doc(LQID)

        data.get().then(doc => {
          if (doc.exists) {
            const { paid, cancelled } = doc.data()
            if (paid) {
              setPaid(true)
              setCancelled(false)
            } else if (cancelled) {
              setCancelled(true)
              setPaid(false)
            } else {
              setPaid(false)
              setCancelled(false)
            }
          } else {
            setPaid(false)
            setCancelled(false)
          }
        })
      }, 2000)
    }

    const styles = {
      loader: {
        margin: 0,
        top: 'auto',
        right: 'auto',
        bottom: '50%',
        left: 'auto',
        position: 'fixed',
        zIndex: 1,
      },
    }
    return (
      <BodyWrapper>
        {loading ? (
          <CenteredDiv vertical>
            <Spinner large secondary style={styles.loader} />
            <P style={styles.loader}>Please wait...</P>
          </CenteredDiv>
        ) : (
          <></>
        )}
        {paymentStatus()}
        {paymentPage()}
        {customer()}

        <H1>
          <b>Ledger :</b> {ledger}
        </H1>
        <P>
          <b>Limit :</b> {ledgerLimit}
        </P>
        <P>
          <b>Overdue: </b>
          {ledgerOverdue}
        </P>
        <br />
        <SearchBar
          value={searched}
          onChange={searchVal => requestSearch(searchVal)}
          onCancelSearch={() => cancelSearch()}
          style={{ marginBottom: '2em' }}
        />
        <TableContainer component={Paper} style={{ maxHeight: 500 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow
                style={{
                  height: '5px',
                  width: '100%',
                  backgroundColor: '#d3d3d3',
                }}
              >
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <FormControlLabel
                    label='Clear All'
                    control={
                      <Checkbox
                        checked={markedAll}
                        onChange={e => handleAddAllLedgers(e, customerList)}
                      />
                    }
                  />
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Ledger ID</h2>
                  </b>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Order ID</h2>
                  </b>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Leader ID</h2>
                  </b>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Amount</h2>
                  </b>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Customer ID</h2>
                  </b>
                </TableCell>
                <TableCell style={{ backgroundColor: '#fe7c19' }}>
                  <b>
                    <h2>Date</h2>
                  </b>
                </TableCell>
                <TableCell>
                  <Button onClick={e => settleLedger(e)}>CLEAR</Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerList.map(data => (
                <TableRow
                  key={data.key}
                  style={{ backgroundColor: data.color }}
                >
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={data.mark}
                          onChange={e => handleAddLedger(e, data.key)}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <P>
                      <b>{data.key}</b>
                    </P>
                  </TableCell>
                  <TableCell>
                    <P>
                      <b>{data.orderID}</b>
                    </P>
                  </TableCell>
                  <TableCell>
                    <P>
                      <b>{data.leaderID}</b>
                    </P>
                  </TableCell>
                  <TableCell>
                    <P>
                      <b>{data.total}</b>
                    </P>
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={e => handleDisplayCustomer(e, data.customerID)}
                    >
                      {data.customerID}
                    </Button>
                  </TableCell>
                  <TableCell colSpan={2}>
                    <P>
                      <b>{data.date}</b>
                    </P>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </BodyWrapper>
    )
  }
  return myCustomers()
}

export default MyCustomers
