import React, { useState, useContext, useEffect } from "react";
import { ToastProvider } from "./contexts/toastContext";
import { UserProvider } from "./contexts/userContext";
import MainRouter from "./MainRouter";
import { requestFirebaseNotificationPermission } from './firebase'

const App = () => {

  useEffect(() => {
    requestFirebaseNotificationPermission()
    .then((firebaseToken) => {
      // eslint-disable-next-line no-console
      console.log(firebaseToken);
    })
    .catch((err) => {
      return err;
    });
  }, []);


  return (
    <ToastProvider>
      <UserProvider>
        <MainRouter />
      </UserProvider>
    </ToastProvider>
  );
};

export default App;
